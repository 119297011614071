import Vue from 'vue';
import { Capacitor } from '@capacitor/core';

import PushNotification from '@/lib/pushNotification';

const alert = new Audio('/audio/hero.mp3');

const NotificationMixin = {
  data() {
    return {
      askNotification: false,
      warnDeniedNotification: false,
    }
  },

  created() {
    this.refreshNotificationState()
  },

  methods: {
    addNotificationListeners() {
      if (Capacitor.isNativePlatform()) {
        PushNotification.addListeners();
      }
    },

    refreshNotificationState() {
      if (!('Notification' in window) || !('permission' in Notification)) {
        console.warn('[WARN] Notification API not found.')
      } else {
        this.askNotification = Notification.permission !== 'granted' && Notification.permission !== 'denied'
        this.warnDeniedNotification = Notification.permission === 'denied'
      }
    },

    handlePermission(permission) {
      // Whatever the user answers, we make sure Chrome stores the information
      if(!('permission' in Notification)) {
        Notification.permission = permission
      }

      this.refreshNotificationState();

      if (Notification.permission === 'granted') {
        Vue.toasted.success(this.$t('success.notificationsAuthorized'), { duration: 2000 })
      } else {
        // Vue.toasted.error('Favor autorizar as notificações. Se estiver usando uma aba anônima, note que alguns navegadores bloqueam notificações em modo anônimo.', { duration: 4000 })
      }
    },

    askNotificationPermissionIfNecessary() {
      if (Capacitor.isNativePlatform()) {
        PushNotification.registerNotificationsIfPossible();
      } else {
        this.refreshNotificationState();
        if (!('Notification' in window) || !('permission' in Notification) || !this.askNotification) {
          // skip prompt
          return
        }
        // Let's check if the browser supports notifications
        if (!('Notification' in window)) {
          console.warn('[WARN] This browser does not support notifications.')
        } else {
          Notification.requestPermission(this.handlePermission)
        }
      }
    },

    showNotification(title, body, onClick) {
      if (Capacitor.isNativePlatform()) {
        // PushNotification.notify('Teste', 'Teste de notificação')
      } else {
        this.askNotificationPermissionIfNecessary()

        try {
          alert.play();
        } catch(err) {
          // example: "play failed because user didn't interact with the document"
          // ignore fails here
          console.log(err)
        }

        if (Notification.permission === 'granted') {
          const notification = new Notification(title, { body, });
          notification.onclick = onClick;
        } else {
          Vue.toasted.info(title + ( body ? ' <br> ' + body : ''), { duration: 5000 });
        }
      }
    },

    unregisterNotifications() {
      if (!Capacitor.isNativePlatform()) { return; }
      
      PushNotification.unregister();
    },
  },
}

export default NotificationMixin
