import { collections } from '@/constants/collections';
import { IS_DEV } from '@/constants';
import { firestoreDb, handleSnapshot } from '@/lib/firebase';
import api from '@/lib/api';
import DateHelper from '@/utils/dateHelper';

const SURVEY_INTERVAL = 1;
const RESET = 'RESET';

export const MUTATIONS = {
  RESET: 'RESET',
  SURVEYS: 'SURVEYS',
  UNSUBSCRIBER: 'UNSUBSCRIBER',
};

export const GETTERS = {
  SURVEYS: 'SURVEYS',
  UNSUBSCRIBER: 'UNSUBSCRIBER',
};

const getDefaultState = () => ({
  surveys: [],
  unsubscriber: null,
});

export const state = getDefaultState();

export const mutations = {
  [MUTATIONS.SURVEYS](state, surveys) {
    state.surveys = surveys;
  },
  [MUTATIONS.UNSUBSCRIBER](state, unsubscriber) {
    state.unsubscriber = unsubscriber;
  },

  [RESET](state) {
    Object.assign(state, getDefaultState());
  },
}

export const getters = {
  [GETTERS.SURVEYS](state) {
    return state.surveys;
  },
  [GETTERS.UNSUBSCRIBER](state) {
    return state.unsubscriber;
  },
}

export const actions = {
  async fetchSurvey(_, params) {
    try {
      const response = await api.get(
        '/surveys',
        { params }
      )
      
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchQuestionTypes() {
    try {
      const response = await api.get(
        '/surveys/question-types',
      )
      
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async sendAnswers(_, payload) {
    try {
      const response = await api.post(
        '/surveys/answers',
        payload,
      )
      
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async surveyShown({ getters, commit }, id) {
    try {
      await api.post(
        '/surveys/view',
        { id },
      );

      const surveys = getters[GETTERS.SURVEYS].filter((survey) => survey.id !== id);
      commit(MUTATIONS.SURVEYS, surveys);
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  async checkSurveys({ getters, rootGetters }, inputSurveys) {
    console.log('Check survey');
    const userInfo = rootGetters['user/GET_READONLY_INFO'];

    console.log(userInfo.lastSurveyDate)
    if (userInfo.lastSurveyDate) {
      console.log('last survey interval', DateHelper.date().diff(DateHelper.date(userInfo.lastSurveyDate), IS_DEV ? 'minute' : 'hour'));
    }

    if (userInfo.lastSurveyDate && DateHelper.date().diff(DateHelper.date(userInfo.lastSurveyDate), IS_DEV ? 'minute' : 'hour') < SURVEY_INTERVAL) {
      return;
    }

    const surveys = inputSurveys || getters[GETTERS.SURVEYS];
    console.log('found', surveys);

    if (surveys.length === 0) {
      return;
    }

    return surveys;
  },

  subscribePending({ getters, rootGetters, commit, dispatch }) {
    const userId = rootGetters['user/GET_USER_UID'];
    const prevUnsubscriber = getters[GETTERS.UNSUBSCRIBER];
    
    if (prevUnsubscriber) {
      prevUnsubscriber();
      commit(MUTATIONS.UNSUBSCRIBER, null);
    }

    const unsubscriber = firestoreDb
      .collection(collections.surveys)
      .where('status', '==', 'pending')
      .where('location', '==', 'on-site')
      .where('userId', '==', userId)
      .onSnapshot((snapshot) => {
        const surveys = handleSnapshot(snapshot);

        console.log('snap', surveys)

        commit(MUTATIONS.SURVEYS, surveys);

        if (surveys.length) {
          dispatch('checkSurveys', surveys);
        }
      });

    commit(MUTATIONS.UNSUBSCRIBER, unsubscriber);
  },

  reset({ commit, dispatch }) {
    dispatch('unsubscribeAll');
    commit(RESET);
  },

  unsubscribeAll({ getters, commit }) {
    const prevUnsubscriber = getters[GETTERS.UNSUBSCRIBER];
    
    if (prevUnsubscriber) {
      prevUnsubscriber();
      commit(MUTATIONS.UNSUBSCRIBER, null);
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
