import api from '@/lib/api';
import { auth } from '@/lib/firebase';

export const actions = {
  async interestedMarketplace(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post('/marketplace/interested',
        payload,
        { headers: { Authorization: `Bearer ${token}` } },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchMarketplaces() {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get('/marketplace', {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  }
};

export default {
  namespaced: true,
  actions,
}