import store from '@/store';

const allowed = [
  'owner-saas-subscription',
];

export default function authAndVerifiedMiddleware(to, from, next) {
  if (allowed.includes(to.name)) {
    return next();
  }

  const readonly = store.getters['user/GET_READONLY_INFO'];

  if (readonly?.isAdmin || readonly?.isSuperAdmin) {
    return next();
  }

  if (!readonly?.ownerSaasId) {
    if (!readonly?.saasRole || readonly?.saasRole === 'user') {
      return next({ name: 'doctor-dashboard' });
    }
  }

  next();
}
