<template>
  <div v-if="isLoading">
    <b-loading v-if="fadeBackground" :is-full-page="isFullPage" :active.sync="isLoading" :can-cancel="canCancel" animation="fade" :class="{ 'full-page': isFullPage }" :style="{ 'min-height': minHeight }">
      <swapping-squares-spinner :animation-duration="1200" :size="spinnerSize" :color="'#0896de'" class="margin-centered">
      </swapping-squares-spinner>
    </b-loading>
    <swapping-squares-spinner v-else :animation-duration="1200" :size="spinnerSize" :color="'#0896de'" class="margin-centered">
    </swapping-squares-spinner>
  </div>
</template>

<script>
// import 'epic-spinners/dist/lib/epic-spinners.min.css'
import SwappingSquaresSpinner from 'epic-spinners/src/components/lib/SwappingSquaresSpinner'

export default {
  name: 'LoadingComponent',
  components: { SwappingSquaresSpinner },

  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    fadeBackground: {
      type: Boolean,
      default: false,
    },
    canCancel: {
      type: Boolean,
      default: false,
    },
    isFullPage: {
      type: Boolean,
      default: false,
    },
    spinnerSize: {
      type: Number,
      default: 120,
    },
    minHeight: {
      type: String,
      default: '500px',
    }
  },
}
</script>

<style scoped>
.full-page {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
}
</style>
