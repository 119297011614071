function timeInDay(time, day) {
  const [ hour, min ] = time.split(':').map(Number);
  return day.clone().hour(hour).minute(min).second(0);
}

function nextWeekDay(day, weekDay) {
  const wd = parseInt(weekDay);
  const startDay = day.day();
  return startDay === wd
    ? day
    : day.add(wd > startDay ? wd - startDay : 7 - startDay + wd, 'd');
}

export {
  nextWeekDay,
  timeInDay,
}
