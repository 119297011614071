import GoogleMapsApiLoader from '@/utils/google-maps-api-loader'
import axios from 'axios';

import { IS_APP } from '@/constants';
import Analytics from '@/lib/analytics';
import events from '@/constants/events';

const SET_LAST_COORD = 'SET_LAST_COORD';
const SET_LAST_LOCATION = 'SET_LAST_LOCATION';
const SET_GMAPS = 'SET_GMAPS';
const SET_CAN_REQUEST_LOCATION = 'SET_CAN_REQUEST_LOCATION';
const SET_CURRENT_PROMISE = 'SET_CURRENT_PROMISE';

const GET_LAST_COORD = 'GET_LAST_COORD';
const GET_LAST_LOCATION = 'GET_LAST_LOCATION';
const GET_GMAPS = 'GET_GMAPS';
const GET_CAN_REQUEST_LOCATION = 'GET_CAN_REQUEST_LOCATION';
const GET_CURRENT_PROMISE = 'GET_CURRENT_PROMISE';

export const state = () => ({
  canRequestLocation: !IS_APP,
  lastCoord: null,
  lastLocation: null,
  gmapsApi: null,
  currentPromise: null,
});

export const mutations = {
  [SET_LAST_LOCATION](state, lastLocation) {
    state.lastLocation = lastLocation;
  },
  [SET_LAST_COORD](state, lastCoord) {
    state.lastCoord = lastCoord;
  },
  [SET_GMAPS](state, gmapsApi) {
    state.gmapsApi = gmapsApi;
  },
  [SET_CAN_REQUEST_LOCATION](state, canRequestLocation) {
    state.canRequestLocation = canRequestLocation;
  },
  [SET_CURRENT_PROMISE](state, currentPromise) {
    state.currentPromise = currentPromise;
  },
}

export const getters = {
  [GET_LAST_COORD](state) {
    return state.lastCoord;
  },
  [GET_LAST_LOCATION](state) {
    return state.lastLocation;
  },
  [GET_GMAPS](state) {
    return state.gmapsApi;
  },
  [GET_CAN_REQUEST_LOCATION](state) {
    return state.canRequestLocation;
  },
  [GET_CURRENT_PROMISE](state) {
    return state.currentPromise;
  },
}

export const actions = {
  async loadApiIfNotAlready({ getters, commit }) {
    const possiblePromise = getters.GET_CURRENT_PROMISE;
    if (possiblePromise !== null) {
      // skip: call has been already made, await too
      await possiblePromise;
      return;
    }

    let gmapsApi = getters.GET_GMAPS;
    if (gmapsApi !== null) {
      return;
    }

    const promise = GoogleMapsApiLoader();
    commit(SET_CURRENT_PROMISE, promise);

    await promise
      .then((gmapsApi) => commit(SET_GMAPS, gmapsApi))
      .finally(() => commit(SET_CURRENT_PROMISE, null));
  },

  setCanRequestLocation({ commit }, canRequest) {
    commit(SET_CAN_REQUEST_LOCATION, canRequest);
  },

  needsLocation({ getters }) {
    return !getters.lastLocation;
  },

  async locationPermission() {
    if ('permissions' in navigator) {
      const result = await navigator.permissions.query({ name: 'geolocation' });

      Analytics.logEvent(events.home_location_request_result, {
        result: result.state,
      });

      return result.state;
    }

    return 'prompt';
  },

  async requestLocation({ commit, getters }) {
    const fetchLocationFromCoord = async (lat, lng) => {
      const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
          key: process.env.GOOGLE_GEOCODE_API_KEY,
          latlng: `${ lat },${ lng }`,
        }
      });
      return response.data;
    }

    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(async (pos) => {
        const lat = pos.coords.latitude;
        const lng = pos.coords.longitude;

        const lastCoord = getters.GET_LAST_COORD || {};
        const lastLocation = getters.GET_LAST_LOCATION;

        if (lastCoord.lat === lat && lastCoord.lng === lng && lastLocation) {
          resolve(lastLocation);
          return;
        }

        commit(SET_LAST_LOCATION, null);
        commit(SET_LAST_COORD, { lat, lng });

        try {
          const response = await fetchLocationFromCoord(lat, lng);

          if (response?.status === 'OK') {
            commit(SET_LAST_LOCATION, response.results[0]);
          }

          resolve();
        } catch (error) {
          reject(error);
        }
      }, reject, { enableHighAccuracy: true });
    });
  },

  // nearby() {},
  // geolocate() {},
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
