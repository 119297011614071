// BEGIN Polyfills
// NOTE: unnecessary with babel-loader:
// import 'core-js/stable'
// import 'regenerator-runtime/runtime'
// import 'intersection-observer'
// import smoothscroll from 'smoothscroll-polyfill'
// smoothscroll.polyfill()
// import './utils/media-devices-polyfill'
// import adapter from 'webrtc-adapter' // eslint-disable-line no-unused-vars
/* eslint-disable no-unused-vars */
// import Promise from 'bluebird'
// global.Promise = Promise
// END Polyfills

import Vue from 'vue';

import { IS_APP } from '@/constants/index';
import { translateError } from '@/utils';
import i18n from './locale';
import store from './store';
import router from './router';

// import firebase from './lib/firebase'

// --------------------------------------
// REGISTER COMPONENTS
// --------------------------------------
import './utils/toasted'
import './utils/lightbox'
import vuetify from './utils/vuetify'
// import './utils/social'
// import './utils/fontawesome'
// import './utils/lazy-load'
// import './utils/vuelidate'
// import './utils/local-storage'
// import './utils/cleave'

// --------------------------------------
// LAYOUTS
// --------------------------------------
import EmptyLayout from '@/layouts/EmptyLayout.vue'
Vue.component(EmptyLayout.name, EmptyLayout)

import ToolbarLayout from '@/layouts/ToolbarLayout.vue'
Vue.component(ToolbarLayout.name, ToolbarLayout)

import SidebarLayout from '@/layouts/SidebarLayout.vue'
Vue.component(SidebarLayout.name, SidebarLayout)

// --------------------------------------
// CUSTOM GLOBAL COMPONENTS
// --------------------------------------
import LoadingComponent from '@/components/LoadingComponent.vue'
Vue.component(LoadingComponent.name, LoadingComponent)

import Breadcrumbs from '@/components/Breadcrumbs.vue';
Vue.component(Breadcrumbs.name, Breadcrumbs)

import CurrencyInput from '@/components/inputs/CurrencyInput.vue';
Vue.component(CurrencyInput.name, CurrencyInput);

import TimeInput from '@/components/inputs/TimeInput.vue';
Vue.component(TimeInput.name, TimeInput);

import DateInput from '@/components/inputs/DateInput.vue';
Vue.component(DateInput.name, DateInput);

import PhoneInput from '@/components/inputs/PhoneInput.vue';
Vue.component(PhoneInput.name, PhoneInput);

import VueTour from 'vue-tour';
require('vue-tour/dist/vue-tour.css');
Vue.use(VueTour);

import VueNumber from "vue-number-animation";
Vue.use(VueNumber);

// --------------------------------------
// CUSTOM GLOBAL DIRECTIVES
// --------------------------------------
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

import VueResizeObserver from "vue-resize-observer";
Vue.directive('resize', VueResizeObserver);

// --------------------------------------
// CUSTOM GLOBAL APIS
// --------------------------------------
import VueCompositionAPI from '@vue/composition-api';
Vue.use(VueCompositionAPI);

// --------------------------------------
// APP
// --------------------------------------
import App from './App.vue'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// import 'intl-tel-input/build/css/intlTelInput.css'

NProgress.configure({ showSpinner: false })

import './assets/scss/_all.scss'

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV === 'development'
Vue.prototype.$isApp = IS_APP;
Vue.prototype.$translateError = translateError;

/* eslint-disable no-new */
new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');

// CAPACITOR PWA ELEMENTS

import { defineCustomElements } from '@ionic/pwa-elements/loader';

defineCustomElements(window);

// Conpass
import './utils/conpass'
