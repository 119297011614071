const collections = {
  addresses: 'addresses',
  analytics: 'analytics',
  all: 'all',
  banks: 'banks',
  balance: 'balance',
  billing: 'billing',
  cards: 'cards',
  chats: 'chats',
  docs: 'docs',
  events: 'events',
  integral: 'integral',
  lastPurchasedSpaces: 'lastPurchasedSpaces',
  locations: 'locations',
  locationSearches: 'location-searches',
  hourPackages: 'hour-packages',
  habitatPass: 'habitat-pass',
  habitatPassPackage: 'habitat-pass-package',
  messages: 'messages',
  metadata: 'metadata',
  monthlyIntegralRequest: 'monthly-integral-request',
  monthlyPackages: 'monthly-packages',
  private: 'private',
  ratings: 'ratings',
  recipients: 'recipients',
  receivers: 'receivers',
  settings: 'settings',
  services: 'services',
  servicesRequests: 'services-requests',
  spaces: 'spaces',
  spaceChanges: 'spaceChanges',
  readonly: 'readonly',
  paymentVouchers: 'payment-vouchers',
  preferences: 'preferences',
  pendings: 'pendings',
  saas: 'saas',
  saasUsers: 'saas-users',
  scheduled: 'scheduled',
  subscriptions: 'subscriptions',
  surveys: 'surveys',
  systemMessages: 'system-messages',
  transactions: 'transactions',
  transfers: 'transfers',
  termsOfUse: 'terms-of-use',
  unities: 'unities',
  unityProducts: 'unity-products',
  usage: 'usage',
  users: 'users',
  userVerifications: 'userVerifications',
  validations: 'validations',
};

const docs = {
  billingInfo: 'billingInfo',
  counters: 'counters',
  customer: 'customer',
  data: 'data',
  info: 'info',
  integral: 'integral',
  habitatPass: 'habitat-pass',
  rating: 'rating',
  readonlyInfo: 'readonlyInfo',
  spaces: 'spaces',
  system: 'system',
  termsOfUse: 'terms-of-use',
  validations: 'validations',
  visit: 'visit',
  users: 'users',
};

export { collections, docs };
