import Vue from 'vue'
import store from '@/store'

export default function authAndVerifiedMiddleware(to, from, next) {
  const isOk = store.getters['user/IS_CONNECTED_AND_VERIFIED'];

  if (isOk) {
    next();
  } else {
    Vue.toasted.show('Você precisa estar conectado para visualizar essa página.', {
      type : 'error',
      icon : 'alert-circle'
    });

    const redirectTo = to.fullPath;
    next({
      name: 'login',
      query: { ...(redirectTo && { redirectTo }) },
    });
  }
}
