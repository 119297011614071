import { auth, firestoreDb } from '@/lib/firebase';
import api from '@/lib/api';

const SET_UNSUBSCRIBER_TRANSACTION = 'SET_UNSUBSCRIBER_TRANSACTION';
const SET_OBSERVERD_TRANSACTION = 'SET_OBSERVERD_TRANSACTION';
const GET_UNSUBSCRIBER_TRANSACTION = 'GET_UNSUBSCRIBER_TRANSACTION';
const RESET = 'RESET';

const getDefaultState = () => ({
  isPaying: false,
  transactions: [],
  hasMoreTransactions: false,
  payables: [],
  hasMorePayables: false,
  unsubscriberTransaction: null,
  observedTransaction: null,
});

export const state = getDefaultState();

export const mutations = {
  SET_IS_PAYING(state, isPaying) {
    state.isPaying = isPaying
  },
  SET_TRANSACTIONS(state, transactions) {
    state.transactions = transactions
  },
  SET_HAS_MORE_TRANSACTIONS(state, hasMoreTransactions) {
    state.hasMoreTransactions = hasMoreTransactions
  },
  SET_PAYABLES(state, payables) {
    state.payables = payables
  },
  SET_HAS_MORE_PAYABLES(state, hasMorePayables) {
    state.hasMorePayables = hasMorePayables
  },
  [SET_OBSERVERD_TRANSACTION](state, transaction) {
    state.observedTransaction = transaction;
  },
  [SET_UNSUBSCRIBER_TRANSACTION](state, unsubscriber) {
    state.unsubscriberTransaction = unsubscriber;
  },

  [RESET](state) {
    Object.assign(state, getDefaultState());
  },
}

export const getters = {
  GET_IS_PAYING(state) {
    return state.isPaying
  },
  GET_TRANSACTIONS(state) {
    return state.transactions
  },
  GET_HAS_MORE_TRANSACTIONS(state) {
    return state.hasMoreTransactions
  },
  GET_PAYABLES(state) {
    return state.payables
  },
  GET_HAS_MORE_PAYABLES(state) {
    return state.hasMorePayables
  },
  [GET_UNSUBSCRIBER_TRANSACTION](state) {
    return state.unsubscriberTransaction;
  }
}

export const actions = {
  buyPackage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('SET_IS_PAYING', true)
      auth.currentUser.getIdToken().then((token) => {
        api.post(
          '/finance/buy-package',
          payload,
          { headers: { Authorization: `Bearer ${token}` } }
        ).then((response) => {
          commit('SET_IS_PAYING', false)
          resolve(response.data.hourPackageId)
        }).catch((err) => {
          reject(err)
        })
      }).catch((err) => {
        reject(err)
      })
    })
  },

  buy({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('SET_IS_PAYING', true)
      auth.currentUser.getIdToken().then((token) => {
        const path = () => {
          if (payload.product === 'hour-package') {
            return '/finance/buy-package'
          }
        }

        api.post(
          path(),
          payload,
          { headers: { Authorization: `Bearer ${token}` } }
        ).then((response) => {
          commit('SET_IS_PAYING', false)
          resolve(response.data.url)
        }).catch((err) => {
          reject(err)
        })
      }).catch((err) => {
        reject(err)
      })
    });
  },

  getPrice({ commit }, { spaceId, product }) {
    return new Promise((resolve, reject) => {
      commit('SET_IS_PAYING', true)
      auth.currentUser.getIdToken().then((token) => {
        api.get(
          '/finance/price',
          {
            params: { spaceId, product },
            headers: { Authorization: `Bearer ${token}` },
          },
        ).then((response) => {
          commit('SET_IS_PAYING', false)
          resolve(response.data.url)
        }).catch((err) => {
          reject(err)
        })
      }).catch((err) => {
        reject(err)
      })
    });
  },

  async fetchDoctorBalance() {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/finance/doctor/balance',
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchDoctorTransactions(_, { userId, first, last, limit, type }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/finance/doctor/transactions',
        {
          params: { userId, first, last, limit, type },
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchDoctorSubscriptions(_, { first, last, limit, type }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/finance/doctor/subscriptions',
        {
          params: { first, last, limit, type },
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async hasOverdueSubscriptions({ rootGetters }) {
    const userUid = rootGetters['user/GET_USER_UID'];
    return await firestoreDb
      .collection('subscriptions')
      .where('status', '==', 'overdue')
      .where('userId', '==', userUid)
      .limit(1)
      .get()
      .then((snapshot) => snapshot.docs.length > 0);
  },

  fetchRentability(_, { month, year, spaceId }) {
    return new Promise((resolve, reject) => {
      auth.currentUser.getIdToken().then((token) => {
        api.get(
          '/finance/rentability',
          { params: { month, year, spaceId }, headers: { Authorization: `Bearer ${token}` } },
        ).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err)
        })
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async payDebit(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/finance/debit/pay',
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }
      
      throw error;
    }
  },

  async fetchInvoice(_, { transactionId }) {
    try {
      const response = await api.get(
        '/finance/invoice',
        {
          params: { transactionId },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchTransactionVoucher(_, { transactionId }) {
    try {
      const response = await api.get(
        '/finance/transaction/voucher',
        {
          params: { transactionId },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async updateTransactionPaymentMethod(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/finance/transaction/payment-method',
        payload,
        { headers: { Authorization: `Bearer ${ token }` }, },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchOrder(_, orderId) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/finance/product-order',
        {
          params: { id: orderId },
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  unsubscribeTransaction({ getters, commit }) {
    const prevUnsubscriber = getters.GET_UNSUBSCRIBER_PAYMENT;
    if (prevUnsubscriber) {
      prevUnsubscriber();
      commit(SET_UNSUBSCRIBER_TRANSACTION, null);
      commit(SET_OBSERVERD_TRANSACTION, null);
    }
  },

  subscribeTransaction({ dispatch, commit }, { transactionId }) {
    // reset previous unsubscriber if any
    dispatch('unsubscribeTransaction');

    const unsubscriber = firestoreDb
      .collection('transactions')
      .doc(transactionId)
      .onSnapshot((snapshot) => {
        commit(SET_OBSERVERD_TRANSACTION, {
          id: snapshot.id,
          ...snapshot.data(),
        });
      })

    commit(SET_UNSUBSCRIBER_TRANSACTION, unsubscriber);
  },

  reset({ commit }) {
    commit(RESET);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
