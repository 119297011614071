<template>
  <div class="flex-1 flex items-center justify-center" v-if="isLoading">
    <v-progress-circular color="primary" indeterminate />
  </div>

  <div class="flex flex-1" v-else>
    <v-card class="flex flex-col" :class="noGutters ? '' : 'py-4'" :elevation="flat ? 0 : undefined" v-if="!isComplete && survey !== null" rounded="lg" :loading="isLoading">
      <v-card-title class="flex-none flex justify-center text-3xl text-center font-bold break-keep">
        {{ title }}
      </v-card-title>

      <v-card-text class="flex flex-col text-black">
        <span class="text-lg">
          {{ subtitle }}
        </span>

        <div class="flex flex-col justify-between gap-8 mt-4">
          <div class="flex flex-column" v-for="(survey, index) in surveyData.questions" :class="center ? 'items-center' : ''" :key="index">
            <span class="text-base font-semibold" :class="center ? 'text-center' : ''">{{ survey.title }}</span>

            <div class="flex w-full max-w-xl">
              <template v-if="survey.fk_type_question === 3">
                <v-text-field
                  v-model="answers[index]"
                  className="w-full"
                  hide-details
                  outlined
                  required
                />
              </template>
  
              <template v-if="survey.fk_type_question === 4">
                <v-text-field
                  v-model="answers[index]"
                  className="w-full"
                  placeholder="email@domain.com"
                  :rules="[rules.required, rules.email]"
                  hide-details
                  outlined
                  required
                />
              </template>
  
              <template v-if="survey.fk_type_question === 6 || survey.fk_type_question === 15">
                <div class="flex gap-5" :class="center ? 'justify-around' : ''">
                  <button
                    class="text-center flex-none flex flex-col items-center gap-2 max-w-10 w-16 md:w-20"
                    :key="number"
                    @click="setRatingAnswer(index, number)"
                    v-for="(number, iconIndex) in 5"
                  >
                    <span
                      :style="{ opacity: (answers[index] || 0) >= number ? 1 : 0.5 }"
                      class="p-2 item-rating-emoji text-3xl md:text-4xl transition-opacity"
                    >
                      {{ getIcon(number) }}
                    </span>
  
                    <span class="font-semibold text-xs" v-if="iconIndex === 0">{{survey.low_score_label}}</span>
                    <span class="font-semibold text-xs" v-if="iconIndex === 4">{{survey.high_score_label}}</span>
                  </button>
                </div>
              </template>
  
              <template v-if="survey.fk_type_question === 10">
                <v-text-field
                  v-model="answers[index]"
                  label="Digite seu comentário"
                  rows="5"
                  hint="Máximo de 200 caracteres"
                  :max="200"
                  hide-details
                  outlined
                  textarea
                  counter
                />
              </template>
  
              <template v-if="survey.fk_type_question === 11">
                <div class="flex" :class="center ? 'justify-around' : ''">
                  <v-radio-group
                    v-model="answers[index]"
                    class="ml-4"
                    :row="survey.answersOBJ.length === 2"
                    hide-details
                  >
                    <v-radio
                      v-for="(option, index) in survey.answersOBJ"
                      :key="index"
                      :label="option.title"
                      :value="option.value"
                    />
                  </v-radio-group>
                </div>
              </template>
  
              <template v-if="survey.fk_type_question === 12">
                <v-select
                  v-model="answers[index]"
                  label="Selecione uma opção"
                  item-text="title"
                  item-value="value"
                  :items="survey.answersOBJ"
                  outlined
                ></v-select>
              </template>
  
              <template v-if="survey.fk_type_question === 13 || survey.fk_type_question === 9">
                <div class="grid-cols-10 gap-1 md:gap-4 ml-4 mt-2" :class="small ? 'grid' : 'grid lg:flex '">
                  <button
                    class="text-center flex-none flex flex-col items-center gap-2 w-8 md:w-20"
                    :key="number"
                    @click="setRatingAnswer(index, number)"
                    v-for="number in (survey.fk_type_question === 9 ? 10 : 5)"
                  >
                    <div
                      class="flex-none flex justify-center items-center text-[#899499] border-2 rounded-full w-[56px] h-[28px] transition-all"
                      :class="(answers[index] || 0) >= number ? activeNumberColor : inactiveNumberColor" 
                    >
                      {{ number }}
                    </div>
  
                    <span class="font-semibold text-xs" v-if="number === 1">{{survey.low_score_label}}</span>
                    <span class="font-semibold text-xs" v-if="number === (survey.fk_type_question === 9 ? 10 : 5)">{{survey.high_score_label}}</span>
                  </button>
                </div>
              </template>
  
              <template v-if="survey.fk_type_question === 16">
                <div class="flex flex-column">
                  <v-rating
                    v-model="answers[index]"
                    class="ma-2"
                    color="success"
                    active-color="primary"
                    :size="50"
                    hover
                  />
  
                  <div class="flex">
                    <span class="text-low">{{survey.low_score_label}}</span>
                    <span class="text-high">{{survey.high_score_label}}</span>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div class="flex justify-end">
            <v-btn
              class="px-8 mt-2 md:mt-0"
              color="primary"
              :loading="isSending"
              @click="sendSearch"
              dark
            >
              <v-icon left>mdi-send</v-icon>
              <span class="font-semibold mt-1">Enviar</span>
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="flex flex-col justify-center" v-if="isComplete" rounded="lg" :loading="isLoading">
      <v-card-title class="justify-center text-3xl font-bold mt-4">
        Pesquisa respondida!
      </v-card-title>

      <v-card-text class="text-center text-lg text-black">
        Suas respostas foram enviadas para o nosso sistema. Obrigado por reservar seu tempo para
        realizá-la.
      </v-card-text>

      <lottie class="mb-4" :options="animationOptions" :height="100" :width="100"/>

      <v-card-actions class="justify-center" v-if="onClose">
        <v-btn
          class="px-8 mt-2 md:mt-0"
          color="primary"
          @click="onClose"
          dark
        >
          <span class="font-semibold mt-1">Fechar</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Lottie from 'vue-lottie';
import { mapGetters } from 'vuex';

import { rules } from '@/lib/rules';
import SuccessfulCheck from '@/assets/animations/successful-check.json';

export default {
  name: 'Survey',

  components: {
    Lottie,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },

    noGutters: {
      type: Boolean,
      default: false,
    },

    flat: {
      type: Boolean,
      default: false,
    },

    small: {
      type: Boolean,
      default: false,
    },

    center: {
      type: Boolean,
      default: false,
    },

    onClose: Function,
  },

  data() {
    return {
      rules,
      showOptions: false,
      isLoading: true,
      isSending: false,
      isComplete: false,

      questionTypes: [],
      answers: {},

      animationOptions: {
        animationData: SuccessfulCheck,
      },
    };
  },

  mounted() {
    this.fetch();
  },

  computed: {
    ...mapGetters('user', {
      isLogged: 'IS_CONNECTED_AND_VERIFIED',
    }),

    survey() {
      return this.data;
    },

    surveyData() {
      return this.survey.data;
    },

    title() {
      return this.surveyData.title;
    },

    subtitle() {
      return this.surveyData.description;
    },

    activeNumberColor() {
      return 'green--text text--darken-3 border-green-500';
    },

    inactiveNumberColor() {
      return 'grey--text border-2 border-[#899499]-700';
    }
  },

  methods: {
    async fetch() {
      this.isLoading = true;

      try {
        const [ questionTypes ] = await Promise.all([
          this.$store.dispatch('surveys/fetchQuestionTypes'),
          this.$store.dispatch('surveys/surveyShown', this.survey.id)
            .catch(() => {
              // Ignore error
            }),
          this.isLogged ? this.$store.dispatch('user/surveyShown')
            .catch(() => {
              // Ignore error
            }) : null,
        ]);

        this.questionTypes = questionTypes;
        this.isComplete = this.survey.status === 'complete';
      } catch (error) {
        this.$toasted.global.custom_error({ message: error.message || this.$t('error.unableToLoadSurvey') })
      }

      this.isLoading = false;
    },

    setRatingAnswer(index, number) {
      this.$set(this.answers, index, number);
    },

    getIcon(number) {
      switch (number) {
        case 1:
          return '😠';
        case 2:
          return '🙁';
        case 3:
          return '😐';
        case 4:
          return '🙂';
        case 5:
          return '😍​';
        default:
          return'';
      }
    },

    async sendSearch() {
      this.isSending = true;

      try {
        if (this.surveyData.questions.length !== Object.keys(this.answers).length) {
          this.$toasted.global.custom_error({message: this.$t('error.invalidFields') });
          return;
        }

        const answers = this.surveyData.questions.map((question, index) => {
          return {
            questionId: question.id_question,
            questionType: question.fk_type_question,
            value: this.answers[index],
          };
        });

        await this.$store.dispatch('surveys/sendAnswers', {
          id: this.survey.id,
          answers,
        });

        this.isComplete = true;
      } catch (error) {
        this.$toasted.global.custom_error({ message: this.$t(error || 'error.defaultMessage') });
        console.error(error);
      } finally {
        this.isSending = false;
      }
    }
  },

}
</script>

<style>
.green--text.text--darken-3 {
  color: #AFE1AF;
}

.text-high, .text-low {
  font-size: 12px;
  margin-top: 5px;
  font-weight: 600;
}

.text-low {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 10px;
  width: 70px;
}

.text-high {
  margin-left: 200px;
}

.text-high-emoji, .text-high-number, .text-high-emoji15 {
  font-size: 12px;
  margin-top: 5px;
  font-weight: 600;
}

.text-high-emoji {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 70px;
  margin-left: 16rem;
}

.text-high-number {
  margin-left: 320px;
}

.item-rating-emoji {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

</style>

<style lang="scss" scoped>
.v-main__wrap {
  display: flex;
}

.v-main__wrap .container {
  display: flex;
}
</style>