// tailwindcss defaults:
// sm: '640px', // => @media (min-width: 640px) { ... }
// md: '768px', // => @media (min-width: 768px) { ... }
// lg: '1024px', // => @media (min-width: 1024px) { ... }
// xl: '1280px', // => @media (min-width: 1280px) { ... }
// '2xl': '1536px', // => @media (min-width: 1536px) { ... }

const sm = 640

const ViewportMixin = {
  methods: {
    getWindowWidth() {
      // not in computed so that it is responsive
      // compatible with IE8 and earlier
      return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    },

    getIsNarrowerThan(size) {
      return this.getWindowWidth() < size
    },

    getIsNarrowerThanSm() {
      return this.getIsNarrowerThan(sm)
    },

    getIsDesktop() {
      // not in computed so that it is "responsive"
      return this.getWindowWidth() >= 1024
    },
  }
}

export default ViewportMixin
