import { auth } from '@/lib/firebase';

class AuthService {

  constructor() {
    this.initialized = new Promise((resolve) => {
      auth.onAuthStateChanged(() => {
        resolve(true);
      });
    });
  }

  async isInitialized() {
    return await this.initialized;
  }

  async currentUser() {
    return auth.currentUser;
  }

  async onAuthStateChanged(cb) {
    auth.onAuthStateChanged(cb);
  }

}

export default new AuthService();