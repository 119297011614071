<template>
  <v-breadcrumbs class="pt-0" :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        class="text-subtitle-2 crumb-item"
        :to="item.to"
        :disabled="item.disabled"
        exact
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: 'Breadcrumbs',

  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>