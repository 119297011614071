export const sanitizeStr = (value) => {
  return value
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '');
};

export const getColorSpaceStatus = (status) => {
  if (status === 'published') return 'primary';
  if (status === 'canceled') return 'red lighten-1';
  if (status === 'maintenance') return 'secondary';
  if (status === 'hidden') return 'grey lighten-1';
  if (status === 'draft') return 'deep-purple lighten-1';
  return 'deep-purple lighten-1';
};

export const prettyAddress = (address) => {
  if (!address) { return 'Sem endereço'; }
  return (
    (address.street ? address.street : '') +
    (address.number ? ', ' + address.number : '') +
    (address.complement ? ' - ' + address.complement : '') +
    (address.neighborhood ? ', ' + address.neighborhood : '') +
    (address.city ? ', ' + address.city : '') +
    (address.state ? '/' + address.state : '') +
    (address.zip ? ', ' + address.zip : '')
  )
};

export const spaceAddress = (space) => {
  let address = space.address.complement ? space.address.complement + ' | ' : '';

  address += space.address.street;

  if (space.address.number) {
    address += ', ' + space.address.number;
  }

  if (space.address.neighborhood) {
    address += ' - ' + space.address.neighborhood;
  }

  if (space.address.city) {
    address += ', ' + space.address.city;
  }

  if (space.address.state) {
    address += ', ' + space.address.state;
  }

  return address;
};

export const roundDecimals = (num) => {
  const m = Number((Math.abs(num) * 100).toPrecision(15));
  return Math.ceil(m) / 100 * Math.sign(num);
}

export const parsePrice = (price) => {
  if (typeof price === 'string') {
    return parseFloat(price.replace(',', '.'));
  }

  return price;
}

export const prettyPrice = (price) => {
  if (!price && price !== 0) { return; }

  let value = price;

  if (typeof price === 'string') {
    value = parseFloat(price.replace(',', '.'));
  }

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return formatter.format(value / 100);
};

export const prettyPaymentMethod = (method) => {
  if (method === 'creditCard') {
    return 'Cartão de Crédito';
  } else if (method === 'pix') {
    return 'Pix';
  } else if (method === 'boleto') {
    return 'Boleto';
  }
};

export const prettyProduct = (product) => {
  switch (product) {
    case 'loose':
      return 'Horas Avulsas';
    case 'hour-package':
      return 'Pacote de Horas';
    case 'habitat-pass':
      return 'Habitat Pass';
    case 'monthly':
      return 'Habitat Fixo - Período Mensal';
    case 'integral':
      return 'Habitat Próprio - Período Integral';
    case 'debit':
      return 'Débito';
    case 'service':
      return 'Serviço';
    default:
      return product;
  }
};

export const prettyStatus = (status) => {
  switch (status) {
    case 'done':
      return 'Concluído';
    case 'transfer_done':
    case 'received':
      return 'Repassado';
    case 'pending':
    case 'transfer_pending':
    case 'transfer_not_sent':
    case 'confirmed':
    case 'transfer_confirmed':
      return 'Em processamento';
    case 'failed':
    case 'transfer_failed':
      return 'Falha';
    case 'overdue':
      return 'Atrasado';
    case 'refunded':
    case 'chargedback':
      return 'Estornado';
    case 'canceled':
    case 'transfer_canceled':
      return 'Cancelado';
    case 'deleted':
      return 'Expirado';
    default:
      return status;
  }
};

export const readableDuration = (duration) => {
  if (duration === 0) { return ''; }

  const result = [];
  const parsed = parseInt(duration);

  if (parsed) {
    result.push(`${ parsed } hora${ parsed > 1 ? 's' : '' }`);
  }

  if (duration % 1) {
    const minutes = Math.round(duration % 1 * 60);
    result.push(`${ minutes } minuto${ minutes > 1 ? 's': '' }`);
  }

  return result.join(' e ');
};

export const isInsideConsecutiveOpeningHours = (space, start, end) => {
  const ranges = [];

  const dayOfWeek = start.day()
  // map periods (morning, afternoon, night indices) to ranges
  const periods = space
    .availabilities
    .filter(av => av.d === dayOfWeek)
    .sort((a, b) => a.p - b.p)
    .map(av => {
      if (av.p === 0) {
        return [ space.morningStart, space.morningEnd ];
      } else if (av.p === 1) {
        return [ space.afternoonStart, space.afternoonEnd ];
      } else if (av.p === 2) {
        return [ space.nightStart, space.nightEnd ];
      }
    });

  periods.forEach((range) => {
    // If ranges empty, push first
    if (ranges.length === 0) {
      ranges.push(range);
    } else {
      // If previous range end time is equal to current start, merge
      if (ranges[ranges.length - 1][1] === range[0]) {
        ranges[ranges.length - 1] = [ ranges[ranges.length - 1][0], range[1] ];
      }
      // Otherwise, push
      else {
        ranges.push(range);
      }
    }
  });

  const date = start.clone();
  const dayRanges = ranges.filter(([a, b]) => a && b);
  // for each range in day
  return dayRanges.some((hourMinRange) => {
    const [a, b] = hourMinRange
    const [aHour, aMin] = a.split(':').map(Number)
    const [bHour, bMin] = b.split(':').map(Number)
    const startRangeInDay = date.hour(aHour).minute(aMin).second(0)
    const endRangeInDay = date.hour(bHour).minute(bMin).second(0)
    return startRangeInDay.isSameOrBefore(start) && end.isSameOrBefore(endRangeInDay)
  });
};

export const getRGBA = (color, alpha) => {
  const rgb = parseInt(color.substring(1), 16);
  const r = (rgb >> 16) & 0xFF;
  const g = (rgb >> 8) & 0xFF;
  const b = (rgb >> 0) & 0xFF;
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const productColor = (product, type) => {
  if (product === 'monthly-package') {
    return type === 'period' ? '#FF9800' : '#3F51B5';
  } else if (product === 'loose') {
    return '#673AB7';
  } else if (product === 'habitat-pass') {
    return '#00BCD4';
  } else if (product === 'hour-package') {
    return '#2196F3';
  }
  
  return '#2196F3';
}

export const eventColor = (event) => {
  // some good colors:
  // 'blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'
  // '#2196F3', '#3F51B5', '#673AB7', '#00BCD4', '#4CAF50', '#FF9800', '#757575'
  let color = '#4CAF50';

  if (event.eventType === 'unavailable') {
    color = 'grey';
  } else if (event.eventType === 'visit') {
    color = '#673AB7';
  } else if (event.eventType === 'reservation') {
    color = productColor(event.details.product, event.details.type);
  }

  return color;
};

export const eventBgColor = (event) => {
  switch (event.status) {
    case 'pending': return 'gray'
    case 'canceled': return 'error'
    case 'pending_cancel': return 'orange'
    case 'pending_payment': return 'orange'
    case 'done':
    case 'confirmed': return 'success'
    default: return '';
  }
};

export const eventFgColor = (event) => {
  switch (event.status) {
    case 'pending': return 'black--text';
    case 'canceled':
    case 'pending_cancel':
    case 'pending_payment':
    case 'done':
    case 'confirmed': return 'white--text '
    default: return '';
  }
};

export const transactionStatusBgColor = (status) => {
  if (status === 'pending') return 'warning';
  if (status === 'done') return 'success';
  if (status === 'transfer') return 'info';
  if (status === 'confirmed' || status === 'received') return 'info';
  if (status === 'overdue' || status === 'deleted') return 'red lighten-1';
  if (status === 'refunded') return 'deep-purple lighten-1';
  if (status === 'refund_requested') return 'deep-purple lighten-1';
  return 'null';
};

export const transactionStatusLabel = (status) => {
  if (status === 'pending') return 'Pendente';
  if (status === 'done' || status === 'transfer_done') return 'Pago';
  if (status === 'transfer') return 'Processando transferência';
  if (status === 'received') return 'Recebido';
  if (status === 'confirmed') return 'Confirmado';
  if (status === 'overdue') return 'Atrasado';
  if (status === 'deleted') return 'Cancelado';
  if (status === 'refunded') return 'Estornado';
  if (status === 'refund_requested') return 'Estorno solicitado';

  return '';
};

export const userTransactionStatusLabel = (status) => {
  if (status === 'pending') {
    return 'Pendente';
  } else if (status === 'confirmed' || status === 'done' || status === 'received') {
    return 'Confirmado';
  } else if (status === 'overdue') {
    return 'Atrasado';
  } else if (status === 'deleted') {
    return 'Cancelado';
  } else if (status === 'refunded') {
    return 'Estornado';
  } else if (status === 'refund_requested') {
    return 'Estorno solicitado';
  }

  return 'Processando pagamento';
};

export const userTransactionStatusBgColor = (status) => {
  if (status === 'pending') {
    return 'warning';
  } else if (status === 'paid') {
    return 'primary';
  } else if (status === 'confirmed' || status === 'done' || status === 'received') {
    return 'info';
  } else if (status === 'overdue' || status === 'deleted') {
    return 'red lighten-1';
  } else if (status === 'refunded') {
    return 'deep-purple lighten-1';
  } else if (status === 'refund_requested') {
    return 'deep-purple lighten-1';
  }

  return 'info';
};

export const subscriptionStatusLabel = (status) => {
  switch (status) {
    case 'active': return 'Ativo';
    case 'inactive': return 'Inativa';
    case 'overdue': return 'Atrasada';
    case 'canceled': return 'Cancelada';
    case 'pending': return 'Pendente';
  }

  return null;
};

export const subscriptionStatusBgColor = (status) => {
  switch (status) {
    case 'active': return 'primary';
    case 'canceled': return 'grey';
    case 'inactive': return 'grey';
    case 'overdue': return 'error';
    case 'pending': return 'warning';
  }

  return null;
};

export const initials = (name) => {
  return name ? name.split(' ').slice(0, 2).map(s => s.charAt(0)).join('') : null;
};

export const spreadTime = (start, end, isStart) => {
  const result = isStart ? [ start ] : [];
  let current = start;

  while (current < end) {
    const [ hour, minute ] = current.split(':');

    if (minute < '30') {
      current = hour + ':30';
    } else {
      const newHour = String(parseInt(hour) + 1).padStart(2, '0');
      current = newHour + ':00';
    }

    if (current < end) {
      result.push(current);
    } else if (!isStart) {
      result.push(end);
    }
  }

  return result;
};

export const spaceHourRanges = (space, isStart) => {
  if (!space) { return []; }

  return space
    .availabilities
    .sort((a, b) => a.p - b.p)
    .map(av => {
      if (av.p === 0) {
        return spreadTime(space.morningStart, space.morningEnd, isStart);
      } else if (av.p === 1) {
        return spreadTime(space.afternoonStart, space.afternoonEnd, isStart);
      } else if (av.p === 2) {
        return spreadTime(space.nightStart, space.nightEnd, isStart);
      }
    })
    .reduce((result, next) => [ ...result, ...next ], []);
};

export const unityHourRanges = (unity, isStart) => {
  if (!unity) { return []; }

  return spreadTime(unity.openTime, unity.closeTime, isStart);
};

export const b64toBlob = (b64Data, contentType = '', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

export const titleCased = (str) => {
  try {
    return str
      .trim()
      .split(' ')
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(' ');
  } catch (error) {
    return str;
  }
};

export const translateError = (error, fallback) => {
  switch (error) {
    case 'not.saas.user': {
      return 'Usuário não é um usuário válido';
    }
    case 'saas.user.not.found': {
      return 'Usuário não encontrado. Favor contatar o suporte';
    }
    case 'main.bank.not.found': {
      return 'Banco não encontrado';
    }
    case 'invalid.value': {
      return 'Valor inválido';
    }
    case 'import.already.running': {
      return 'Importação já em andamento';
    }
    case 'auth/invalid-login-credentials': {
      return 'Usuário ou senha incorretos';
    }
    case 'auth/user-not-found': {
      return 'Usuário ou senha incorretos';
    }
    case 'auth/wrong-password': {
      return 'Usuário ou senha incorretos';
    }
    case 'auth/email-already-in-use': {
      return 'E-mail já em uso. Use o espaço de login para se conectar';
    }
    case 'auth/email-already-exists': {
      return 'E-mail já em uso';
    }
    case 'auth/popup-closed-by-user': {
      return 'Popup de login fechado pelo usuário';
    }
    case 'auth/popup-blocked': {
      return 'Popup de login bloqueado pelo navegador. Por favor desbloqueie e tente novamente';
    }
    default: {
      return fallback || error;
    }
  }
};

export * from './date-utils';
export * from './mask';
