import { firestoreDb } from '@/lib/firebase';
import { collections } from '@/constants/collections';
import api from '@/lib/api';
// import Vue from 'vue'
// import { ToastProgrammatic as Toast } from 'buefy'

const SET_RESULTS = 'SET_RESULTS';
const SET_CHEAPEST_RESULTS = 'SET_CHEAPEST_RESULTS';
const SET_HIGHLIGHTED_RESULTS = 'SET_HIGHLIGHTED_RESULTS';

const GET_RESULTS = 'GET_RESULTS';
const GET_CHEAPEST_RESULTS = 'GET_CHEAPEST_RESULTS';
const GET_HIGHLIGHTED_RESULTS = 'GET_HIGHLIGHTED_RESULTS';

const HIGHLIGHT_LIMIT = 10;

export const state = () => ({
  results: [],
  highlightedResults: [],
  cheapestResults: [],
  // total: 0,
  isLoadingResults: true,
})

export const mutations = {
  // SET_TOTAL(state, total) {
  //   state.total = total
  // },
  [SET_RESULTS](state, results) {
    state.results = [ ...results ];
  },
  [SET_CHEAPEST_RESULTS](state, results) {
    state.cheapestResults = [ ...results ];
  },
  [SET_HIGHLIGHTED_RESULTS](state, results) {
    state.highlightedResults = [ ...results ];
  },
  SET_IS_LOADING_RESULTS(state, isLoadingResults) {
    state.isLoadingResults = isLoadingResults;
  },
  // SET_DOCTOR_SELECTED_ROOM(state, { DoctorId, RoomId }) {
  //   const index = state.results.findIndex(r => r.id === DoctorId)
  //   if (index !== -1) {
  //     Vue.set(state.results, index, { ...state.results[index], selectedRoomId: RoomId })
  //   }
  // },
}

export const getters = {
  [GET_RESULTS](state) {
    return state.results;
  },
  [GET_CHEAPEST_RESULTS](state) {
    return state.cheapestResults;
  },
  [GET_HIGHLIGHTED_RESULTS](state) {
    return state.highlightedResults;
  },
  GET_IS_LOADING_RESULTS(state) {
    return state.isLoadingResults;
  },
}

export const actions = {

  async fetch({ commit }, payload) {
    commit('SET_IS_LOADING_RESULTS', true);

    try {
      const response = await api.get(
        '/space/results',
        {
          params: {
            ...payload,
            viewport: JSON.stringify(payload.viewport),
          },
        }
      );

      commit(SET_RESULTS, response.data);
    } catch (error) {
      console.log('Error fetching results', error);
      commit(SET_RESULTS, []);
    }
    commit('SET_IS_LOADING_RESULTS', false);
  },

  async fetchClosest(_, payload) {
    try {
      const response = await api.get(
        '/space/closest',
        {
          params: {
            ...payload,
            viewport: JSON.stringify(payload.viewport),
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log('Error fetching closest results', error);
    }
  },

  async fetchHighlights({ commit, getters }, { center, radius, specializationId, force }) {
    if (!force && getters.GET_HIGHLIGHTED_RESULTS.length) {
      return getters.GET_HIGHLIGHTED_RESULTS;
    }

    try {
      const response = await api.get(
        '/space/highlights',
        {
          params: {
            center,
            radius,
            specializationId,
          },
        }
      );
      const results = response.data;
  
      // If center was given but not enough result
      // query without it
      if (center && results.length < HIGHLIGHT_LIMIT) {
        const response = await api.get(
          '/space/highlights',
          {
            params: {
              specializationId,
              limit: HIGHLIGHT_LIMIT * 2,
            },
          }
        );
        const newResults = response.data;
        const currentResultsIds = new Set([ ...results.map((r) => r.id) ]);
        const filtered = newResults.filter((r) => !currentResultsIds.has(r.id));
  
        results.push(...filtered);
      }
  
      const sliced = results.slice(0, HIGHLIGHT_LIMIT);
  
      commit(SET_HIGHLIGHTED_RESULTS, sliced);
  
      return sliced;
    } catch (error) {
      console.log(error);

      return [];
    }
  },

  async fetchCheapest({ commit, getters }, { center, radius, specializationId, force }) {
    if (!force && getters.GET_CHEAPEST_RESULTS.length) {
      return getters.GET_CHEAPEST_RESULTS;
    }

    const response = await api.get(
      '/space/cheapest',
      {
        params: {
          center,
          radius,
          specializationId,
        },
      }
    );
    const results = response.data;

    // If center was given but not enough result
    // query without it
    if (center && results.length < HIGHLIGHT_LIMIT) {
      const response = await api.get(
        '/space/cheapest',
        {
          params: {
            center,
            radius,
            specializationId,
          },
        }
      );
      const newResults = response.data;
      const currentResultsIds = new Set([ ...results.map((r) => r.id) ]);
      const filtered = newResults.filter((r) => !currentResultsIds.has(r.id));

      results.push(...filtered);
    }

    const sliced = results.slice(0, HIGHLIGHT_LIMIT);

    commit(SET_CHEAPEST_RESULTS, sliced);

    return sliced;
  },

  async logSearch({ rootGetters }, { query, place }) {
    try {
      const appConfig = rootGetters['app/GET_CONFIG'];
      const userInfo = rootGetters['user/GET_USER_INFO'];
      const userLocation = rootGetters['gmaps/GET_LAST_LOCATION'];
      const components = place?.address_components ?? [];
      const payload = {
        createdAt: new Date(),
        ...query,
        userId: userInfo?.id ?? null,
        userName: userInfo?.name ?? null,
        userLocation: userLocation?.formatted_address ?? null,
        saasId: appConfig.saasId || null,
      };
  
      if (components.length) {
        const city = components.find(c => c.types.includes('administrative_area_level_2'));
        const state = components.find(c => c.types.includes('administrative_area_level_1'));
  
        payload.city = city?.long_name ?? null;
        payload.state = state?.long_name ?? null;
      }
  
      await firestoreDb
        .collection(collections.locationSearches)
        .add(payload);
    } catch (error) {
      console.log('Error logging search', error);
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
