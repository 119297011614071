export const IS_APP = process.env.PRODUCT === 'app';
export const IS_DEV = process.env.NODE_ENV === 'development';
export const DEFAULT_PAGE_TITLE = 'Habitat Consultórios | Alugue seu consultório por hora, dia ou mês';
export const MONTHLY_MIN_HOURS_RESERVATION = 4;
export const MAX_RECEIVERS = 4;
export const NEW_BUILD_CHECK_INTERVAL = 1000 * 60;
export const ALL_TOLERANCES_IN_MINUTES = [
  { value: null, text: 'Sem tolerância' },
  { value: 10, text: '10 minutos' },
  { value: 15, text: '15 minutos' },
  { value: 20, text: '20 minutos' },
  { value: 30, text: '30 minutos' },
];

export const HABITAT_PASS_RENEW_OPTIONS = {
  renew: 'renew',
  extra: 'extra',
  loose: 'loose',
};

export * from './collections';
