import axios from 'axios';
import api from '@/lib/api';

const GET_CONFIG = 'GET_CONFIG';
const SET_CONFIG = 'SET_CONFIG';
const GET_IS_BLURRED = 'GET_IS_BLURRED';
const SET_IS_BLURRED = 'SET_IS_BLURRED';

const RESET = 'RESET';

const getDefaultState = () => ({
  config: null,
  isBlurred: false,
});

export const state = getDefaultState();

export const mutations = {
  [SET_CONFIG](state, config) {
    state.config = config;
  },
  [SET_IS_BLURRED](state, isBlurred) {
    state.isBlurred = isBlurred
  },

  [RESET]() {
    // TODO: disabling to avoid resetting the saas config
    // Object.assign(state, getDefaultState());
  },
}

export const getters = {
  [GET_CONFIG](state) {
    return state.config;
  },
  [GET_IS_BLURRED](state) {
    return state.isBlurred
  },
}

export const actions = {
  blur({ commit }, blur) {
    commit(SET_IS_BLURRED, blur);
  },

  reset({ commit }) {
    commit(RESET);
  },

  async loadConfig({ commit, getters }) {
    if (getters[GET_CONFIG]) {
      return getters[GET_CONFIG];
    }

    try {
      const response = await api.get('/app/config');

      commit(SET_CONFIG, response.data);

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async checkVersionBuild() {
    try {
      const response = await axios.get('/api/build-id');

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
