import { auth, firebase, firestoreDb } from '@/lib/firebase';
import api from '@/lib/api';
import { CHATS_COLLECTION, MESSAGES_COLLECTION } from '@/constants/chatConstants.js';
import { HABITAT_HELP_UID } from '../../constants/chatConstants';

const SET_IS_LOADING = 'SET_IS_LOADING';
const GET_IS_LOADING = 'GET_IS_LOADING';

const SET_CHATS = 'SET_CHATS';
const GET_CHATS = 'GET_CHATS';

const SET_MESSAGES = 'SET_MESSAGES';
const GET_MESSAGES = 'GET_MESSAGES';

const SET_CHAT_UNSUBSCRIBE = 'SET_CHAT_UNSUBSCRIBE';
const GET_CHAT_UNSUBSCRIBE = 'GET_CHAT_UNSUBSCRIBE';

const SET_MESSAGE_UNSUBSCRIBE = 'SET_MESSAGE_UNSUBSCRIBE';
const GET_MESSAGE_UNSUBSCRIBE = 'GET_UNSUBGET_MESSAGE_UNSUBSCRIBESCRIBE';

const RESET = 'RESET';

const getDefaultState = () => ({
  isLoading: false,
  chats: [],
  messages: [],
  chatUnsubscribe: null,
  messageUnsubscribe: null,
});

export const state = getDefaultState();

export const mutations = {
  [SET_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [SET_CHATS](state, chats) {
    state.chats = chats;
  },
  [SET_MESSAGES](state, messages) {
    state.messages = messages;
  },
  [SET_CHAT_UNSUBSCRIBE](state, unsubscribe) {
    state.chatUnsubscribe = unsubscribe;
  },
  [SET_MESSAGE_UNSUBSCRIBE](state, unsubscribe) {
    state.messageUnsubscribe = unsubscribe;
  },

  [RESET](state) {
    Object.assign(state, getDefaultState());
  },
}

export const getters = {
  [GET_IS_LOADING](state) {
    return state.isLoading;
  },
  [GET_CHATS](state) {
    return state.chats;
  },
  [GET_MESSAGES](state) {
    return state.messages;
  },
  [GET_CHAT_UNSUBSCRIBE](state) {
    return state.chatUnsubscribe;
  },
  [GET_MESSAGE_UNSUBSCRIBE](state) {
    return state.messageUnsubscribe;
  },
}

export const actions = {
  async send(_, { message }) {
    await firestoreDb.collection(MESSAGES_COLLECTION).add({
      ...message,
      timestamp: new Date(),
    });
    await firestoreDb.collection(CHATS_COLLECTION).doc(message.chatId).update({
      [`unreadCounts.${ message.toId }`]: firebase.firestore.FieldValue.increment(1),
      lastMessage: message.content,
      lastMessageTimestamp: new Date(),
    });
  },

  async read(_, { messageId, chatId, userId }) {
    await firestoreDb
      .collection(MESSAGES_COLLECTION)
      .doc(messageId)
      .update({
        [`wasRead.${ userId }`]: true,
      });
    // NOTE: done in trigger now
    // decrement count
    const docRef = firestoreDb
      .collection(CHATS_COLLECTION)
      .doc(chatId);

    return firestoreDb
      .runTransaction((transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(docRef).then((doc) => {
          if (doc.exists) {
            transaction.update(docRef, { [`unreadCounts.${ userId }`]: Math.max(0, doc.data().unreadCounts[userId] - 1) })
          }
        })
      });
  },

  file(_, { messageId, otherId, files }) {
    return new Promise((resolve, reject) => {
      // commit(SET_IS_SENDING, true)
      auth.currentUser.getIdToken().then((token) => {
        const formData = new FormData();
        files.forEach((f) => formData.append('files[]', f))
        formData.set('messageId', messageId)
        formData.set('otherId', otherId)

        api.post(
          '/chat/file',
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            },
          },
        )
        .then((response) => resolve(response.data))
        .catch(reject)
        // .finally(() => commit(SET_IS_SENDING, false))
      })
      .catch((err) => {
        // commit(SET_IS_SENDING, false)
        reject(err)
      })
    })
  },

  async createHelpdeskChat({ rootGetters }) {
    const userUid = rootGetters['user/GET_USER_UID'];
    const userInfo = rootGetters['user/GET_USER_INFO'];
    const newChat = {
      chatId: `chat-${ userUid }`,
      firstUserId: userUid,
      firstName: userInfo,
      isHelpDesk: true,
      userIds: [ userUid, HABITAT_HELP_UID ],
      names: [ userInfo.name , 'Habitat' ],
      unreadCounts: 0,
      lastMessageTimestamp: null,
    };
    const doc = await firestoreDb
      .collection(CHATS_COLLECTION)
      .add(newChat);

    return doc.id;
  },

  async loadChats({ commit, rootGetters, getters }, { isHelpDesk }) {
    commit(SET_IS_LOADING, true);
    commit(SET_CHATS, []);

    getters.GET_CHAT_UNSUBSCRIBE?.();

    const userUid = rootGetters['user/GET_USER_UID'];
    const isSuperAdmin = rootGetters['user/GET_IS_SUPER_ADMIN'];

    // TODO use limit and lazy scrolling
    let query = firestoreDb
      .collection(CHATS_COLLECTION)
      .where('isHelpDesk', '==', isHelpDesk);

    if (isSuperAdmin) {
      query = query
        .where('userIds', 'array-contains', userUid);
    }

    const unsubscribe = query
      .orderBy('lastMessageTimestamp', 'desc')
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const chats = [ ...getters.GET_CHATS ];
          const newVal = { id: change.doc.id, ...change.doc.data() };
          if (change.type === 'added') {
            // newest on top
            commit(SET_CHATS, [
              ...chats,
              newVal,
            ]);
          }
          if (change.type === 'removed') {
            commit(SET_CHATS, chats.filter(({ id }) => id !== change.doc.id));
          }
          if (change.type === 'modified') {
            const index = chats.findIndex(({ id }) => id === change.doc.id);

            if (index === -1) {
              return;
            }

            chats[index] = newVal;
            commit(SET_CHATS, chats);
          }
        });

        commit(SET_IS_LOADING, false);
      });

    commit(SET_CHAT_UNSUBSCRIBE, unsubscribe);
  },

  loadMessages({ commit, getters }, { chatId }) {
    commit(SET_MESSAGES, []);
    
    getters.GET_MESSAGE_UNSUBSCRIBE?.();

    const limit = 10;
    const unsubscribe = firestoreDb.collection(MESSAGES_COLLECTION)
      .where('chatId', '==', chatId)
      .orderBy('timestamp', 'desc')
      .limit(limit)
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const messages = [ ...getters.GET_MESSAGES ];
          const newVal = { id: change.doc.id, ...change.doc.data() };
          if (change.type === 'added') {
            commit(SET_MESSAGES, [
              newVal,
              ...messages,
            ]);
          }
          if (change.type === 'removed') {
            commit(SET_MESSAGES, messages.filter(({ id }) => id !== change.doc.id));
          }
          if (change.type === 'modified') {
            const index = messages.findIndex(({ id }) => id === change.doc.id);

            if (index === -1) {
              return;
            }

            messages[index] = newVal;
            commit(SET_MESSAGES, messages);
          }
        });
      });

    commit(SET_MESSAGE_UNSUBSCRIBE, unsubscribe);
  },

  reset({ commit }) {
    commit(RESET);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
