import { auth } from '@/lib/firebase';
import api from '@/lib/api';
import DateHelper from '@/utils/dateHelper';

const RESET = 'RESET';

const getDefaultState = () => ({
  events: null,
  isLoadingEvents: false,

  integralAvailabities: null,
  firstDayToInfinity: null,
  lastDayBeforeReservation: null,
  isLoadingIntegralAvailabilities: false,
});

export const state = getDefaultState();

export const mutations = {
  // TODO cache events in dict
  SET_EVENTS(state, events) {
    state.events = events
  },
  SET_IS_LOADING_EVENTS(state, isLoadingEvents) {
    state.isLoadingEvents = isLoadingEvents
  },

  SET_INTEGRAL_AVAILABILITIES(state, integralAvailabities) {
    state.integralAvailabities = integralAvailabities
  },
  SET_FIRST_DAY_TO_INFINITY(state, firstDayToInfinity) {
    state.firstDayToInfinity = firstDayToInfinity
  },
  SET_LAST_DAY_BEFORE_RESERVATION(state, lastDayBeforeReservation) {
    state.lastDayBeforeReservation = lastDayBeforeReservation
  },
  SET_IS_LOADING_INTEGRAL_AVAILABILITIES(state, isLoadingIntegralAvailabilities) {
    state.isLoadingIntegralAvailabilities = isLoadingIntegralAvailabilities
  },

  [RESET](state) {
    Object.assign(state, getDefaultState());
  },
}

export const getters = {
  GET_EVENTS(state) {
    return state.events
  },
  GET_IS_LOADING_EVENTS(state) {
    return state.isLoadingEvents
  },

  GET_INTEGRAL_AVAILABILITIES(state) {
    return state.integralAvailabities
  },
  GET_FIRST_DAY_TO_INFINITY(state) {
    return state.firstDayToInfinity
  },
  GET_LAST_DAY_BEFORE_RESERVATION(state) {
    return state.lastDayBeforeReservation
  },
  GET_IS_LOADING_INTEGRAL_AVAILABILITIES(state) {
    return state.isLoadingIntegralAvailabilities
  },
}

export const actions = {
  async fetchAvailabilities({ commit }, { spaceId, type, intent, rangeStart, rangeEnd, ignoredEvents }) {
    commit('SET_IS_LOADING_EVENTS', true);

    try {
      const response = await api.get(
        '/timeslot/availabilities-in-range',
        {
          params: { spaceId, type, intent, rangeStart, rangeEnd, ignoredEvents },
        }
      );

      const events = response.data.events.map(ev => ({
        ...ev,
        start: DateHelper.date(ev.start).toDate(),
        end: DateHelper.date(ev.end).toDate(),
      }));
      commit('SET_EVENTS', events);
      commit('SET_IS_LOADING_EVENTS', false);
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchServicesUsage(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/timeslot/services/usage',
        {
          params,
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async scheduleVisit(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.put(
        '/timeslot/schedule-visit',
        payload,
        {  headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data.eventId;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  reset({ commit }) {
    commit(RESET);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
