import { firebase } from '@/lib/firebase';

export const uploadImage = async (options) => {
  const {
    file,
    path,
    metadata: extraMetadata,
  } = options;
  const metadata = {
    cacheControl: 'public,max-age=3600',
    ...extraMetadata,
  };
  const isString = typeof file === 'string';
  const storage = firebase.storage().ref(path);

  try {
    const snapshot = await (isString ? storage.putString(file, 'base64') : storage.put(file))

    snapshot.ref.updateMetadata(metadata);

    return snapshot.ref.getDownloadURL();
  } catch {
    return null;
  }
};