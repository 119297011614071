import {
  AppTrackingTransparency,
} from 'capacitor-plugin-app-tracking-transparency';
import { isIOS } from './app';

const getATTStatus = async () => {
  if (isIOS()) {
    let response = await AppTrackingTransparency.getStatus()

    if (response.status === 'notDetermined') {
      response = await requestATTPermission();

      // Giving time to close permission alert
      return new Promise((resolve) => {
        setTimeout(() => resolve(response.status), 500);
      });
    }

    return response.status;
  }

  return undefined;
};

const requestATTPermission = async () => {
  return await AppTrackingTransparency.requestPermission();
}

export {
  getATTStatus,
  requestATTPermission,
};
