import Vue from 'vue';
import dayjs from 'dayjs';
import { auth, firestoreDb } from '@/lib/firebase';
import api from '@/lib/api';

const GET_ALL_PENDING_SPACES_COUNT = 'GET_ALL_PENDING_SPACES_COUNT';
const GET_ALL_PENDING_HABITAT_PASS_COUNT = 'GET_ALL_PENDING_HABITAT_PASS_COUNT';
const GET_ALL_PENDING_INTEGRAL_COUNT = 'GET_ALL_PENDING_INTEGRAL_COUNT';
const GET_UNSUBSCRIBERS = 'GET_UNSUBSCRIBERS';

const SET_ALL_PENDING_SPACES_COUNT = 'SET_ALL_PENDING_SPACES_COUNT';
const SET_ALL_PENDING_HABITAT_PASS_COUNT = 'SET_ALL_PENDING_HABITAT_PASS_COUNT';
const SET_ALL_PENDING_INTEGRAL_COUNT = 'SET_ALL_PENDING_INTEGRAL_COUNT';
const SET_LAST_USER = 'SET_LAST_USER';
const SET_UNSUBSCRIBERS = 'SET_UNSUBSCRIBERS';

const RESET = 'RESET';

const getDefaultState = () => ({
  stats: null,
  isLoadingStats: true,
  // users
  users: [],
  lastUser: null,
  // usersHeaders: [],
  isLoadingUsers: true,
  // spaces
  spaces: [],
  // spacesHeaders: [],
  isLoadingSpaces: true,

  adminsAndSuper: {},
  isLoadingAdminsAndSuper: true,

  isAddingAdmin: false,
  isRevokingAdmin: false,

  unsubscribers: [],
  pendingSpacesCount: 0,
  pendingHabitatPassCount: 0,
  pendingIntegralCount: 0,
});

export const state = getDefaultState();

export const mutations = {
  // dashboard
  SET_STATS(state, stats) {
    state.stats = stats
  },
  SET_IS_LOADING_STATS(state, isLoadingStats) {
    state.isLoadingStats = isLoadingStats
  },
  // users
  SET_USERS(state, users) {
    state.users = users
  },
  [SET_LAST_USER](state, user) {
    state.lastUser = { ...user };
  },
  // SET_USERS_HEADERS(state, usersHeaders) {
  //   state.usersHeaders = usersHeaders
  // },
  SET_IS_LOADING_USERS(state, isLoadingUsers) {
    state.isLoadingUsers = isLoadingUsers
  },
  // spaces
  SET_SPACES(state, spaces) {
    state.spaces = spaces
  },
  // SET_SPACES_HEADERS(state, spacesHeaders) {
  //   state.spacesHeaders = spacesHeaders
  // },
  SET_IS_LOADING_SPACES(state, isLoadingSpaces) {
    state.isLoadingSpaces = isLoadingSpaces
  },
  // admins and super admins
  SET_ADMINS_AND_SUPER(state, adminsAndSuper) {
    state.adminsAndSuper = adminsAndSuper
  },
  SET_IS_LOADING_ADMINS_AND_SUPER(state, isLoadingAdminsAndSuper) {
    state.isLoadingAdminsAndSuper = isLoadingAdminsAndSuper
  },
  SET_IS_ADDING_ADMIN(state, isAddingAdmin) {
    state.isAddingAdmin = isAddingAdmin
  },
  SET_IS_REVOKING_ADMIN(state, isRevokingAdmin) {
    state.isRevokingAdmin = isRevokingAdmin
  },
  [SET_ALL_PENDING_SPACES_COUNT](state, count) {
    state.pendingSpacesCount = count;
  },
  [SET_ALL_PENDING_HABITAT_PASS_COUNT](state, count) {
    state.pendingHabitatPassCount = count;
  },
  [SET_ALL_PENDING_INTEGRAL_COUNT](state, count) {
    state.pendingIntegralCount = count;
  },
  [SET_UNSUBSCRIBERS](state, unsubscribers) {
    state.unsubscribers = unsubscribers;
  },

  [RESET](state) {
    if (state.unsubscribers) {
      state.unsubscribers.forEach((u) => u());
    }

    Object.assign(state, getDefaultState());
  },
}

export const getters = {
  // dashboard
  GET_STATS(state) {
    return state.stats
  },
  GET_IS_LOADING_STATS(state) {
    return state.isLoadingStats
  },
  // users
  GET_USERS(state) {
    return state.users
  },
  // GET_USERS_HEADERS(state) {
  //   return state.usersHeaders
  // },
  GET_IS_LOADING_USERS(state) {
    return state.isLoadingUsers
  },
  // spaces
  GET_SPACES(state) {
    return state.spaces
  },
  // GET_SPACES_HEADERS(state) {
  //   return state.spacesHeaders
  // },
  GET_IS_LOADING_SPACES(state) {
    return state.isLoadingSpaces
  },
  // admins and super
  GET_ADMINS_AND_SUPER(state) {
    return state.adminsAndSuper
  },
  GET_IS_LOADING_ADMINS_AND_SUPER(state) {
    return state.isLoadingAdminsAndSuper
  },
  GET_IS_ADDING_ADMIN(state) {
    return state.isAddingAdmin
  },
  GET_IS_REVOKING_ADMIN(state) {
    return state.isRevokingAdmin
  },
  [GET_ALL_PENDING_SPACES_COUNT](state) {
    return state.pendingSpacesCount;
  },
  [GET_ALL_PENDING_HABITAT_PASS_COUNT](state) {
    return state.pendingHabitatPassCount;
  },
  [GET_ALL_PENDING_INTEGRAL_COUNT](state) {
    return state.pendingIntegralCount;
  },
  [GET_UNSUBSCRIBERS](state) {
    return state.unsubscribers;
  }
}

export const actions = {
  async fetchUsers(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/users',
        { params, headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchNameUsers() {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/users/name',
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchSaas(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/saas',
        { params, headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchSaasById(_, id) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        `/admin/saas/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchSaasUsers(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(`admin/saas/${params.id}/users`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }
      throw error;
    }
  },

  async fetchSaasSpaces(_, id) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        `/admin/saas/${ id }/spaces`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchSaasUnities(_, id) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        `/admin/saas/${ id }/unities`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchSaasTeam(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(`admin/saas/${params.id}/team`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }
      throw error;
    }
  },

  async fetchSaasSubscription(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(`admin/saas/${params.id}/subscription`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }
      throw error;
    }
  },

  async updateSaasSubscription(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.put(
        `admin/saas/${params.id}/subscription`,
        params,
        {
          headers: { Authorization: `Bearer ${token}` },
        });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }
      throw error;
    }
  },

  async fetchUser({ commit, state }, { id, useCache = false }) {
    if (useCache &&
        state.lastUser?.id === id
        && state.lastUser.cacheUntil > dayjs().toDate()) {
      return state.lastUser;
    }

    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/user',
        {
          params: { id },
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      commit(SET_LAST_USER, {
        ...response.data,
        cacheUntil: dayjs().add(5, 'minute').toDate(), // local cache
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchSaasEvents(_, { saasId, start, end }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const id = saasId;
      const response = await api.get(
        `/admin/saas/${ id }/events`,
        {
          params: { start, end },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchUserEvents(_, { userId, start, end }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/user/events',
        {
          params: { userId, start, end },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchSystemMessages() {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/system/messages',
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchUsersImport() {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get('/admin/users/import', {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }
      throw error;
    }
  },

  async importUsers(_, { file }) {
    const token = await auth.currentUser.getIdToken();

    const formData = new FormData();
    formData.append('file', file);

    try {
      await api.post(
        'admin/users/import',
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      )
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async sendSystemMessage(_, message) {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post(
        '/admin/system/message',
        message,
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async enableUser(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post(
        '/admin/user/enable',
        params,
        { headers: { Authorization: `Bearer ${token}` } },
      );
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async disableUser(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post(
        '/admin/user/disable',
        params,
        { headers: { Authorization: `Bearer ${token}` } },
      );
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async userToken(_, id) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/user/token',
        {
          params: { id },
          headers: { Authorization: `Bearer ${token}` }
        },
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchUnity(_, id) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/unity',
        {
          params: { id },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchUnities(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/unities',
        { params, headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchUnitySpaces(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/unity/spaces',
        { params, headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchOwnerSpaces(_, userId) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/owner/spaces',
        {
          params: { userId },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async enableSpace(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post(
        '/admin/space/enable',
        params,
        { headers: { Authorization: `Bearer ${token}` } },
      );
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async disableSpace(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post(
        '/admin/space/disable',
        params,
        { headers: { Authorization: `Bearer ${token}` } },
      );
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async toggleSales(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post(
        '/admin/unity/toggleSales',
        params,
        { headers: { Authorization: `Bearer ${token}` } },
      );
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async editCommission(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post(
        '/admin/unity/commission',
        params,
        { headers: { Authorization: `Bearer ${token}` } },
      );
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async editSaasCommission(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post(
        `/admin/saas/${params.saasId}/commission`,
        params,
        { headers: { Authorization: `Bearer ${token}` } },
      );
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  listSpaces({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('SET_IS_LOADING_SPACES', true)
      auth.currentUser.getIdToken().then((token) => {
        api.get(
          '/admin/spaces',
          {
            params,
            headers: { Authorization: `Bearer ${token}` },
          }
        ).then((response) => {
          const spaces = response.data.spaces
          commit('SET_SPACES', spaces)
          commit('SET_IS_LOADING_SPACES', false)
          // const spacesHeaders = response.data.headers
          // commit('SET_SPACES_HEADERS', spacesHeaders)
          resolve()
        }).catch((err) => {
          reject(err)
        })
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async fetchUserCreditCards(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/user/credit-cards',
        { params, headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchStats({ commit }, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/stats',
        { params, headers: { Authorization: `Bearer ${token}` } }
      );

      const stats = response.data.stats
      commit('SET_STATS', stats);
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    } finally {
      commit('SET_IS_LOADING_STATS', false);
    }
  },

  async fetchSpacesGeo(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/spaces/geo',
        { params, headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  fetchAdminsAndSuper({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('SET_IS_LOADING_ADMINS_AND_SUPER', true)
      auth.currentUser.getIdToken().then((token) => {
        api.get(
          '/admin/admin-and-super',
          { params, headers: { Authorization: `Bearer ${token}` } }
        ).then((response) => {
          commit('SET_ADMINS_AND_SUPER', response.data)
          commit('SET_IS_LOADING_ADMINS_AND_SUPER', false)
          resolve()
        }).catch((err) => {
          reject(err)
        })
      }).catch((err) => {
        reject(err)
      })
    })
  },

  addAdmin({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      commit('SET_IS_ADDING_ADMIN', true)
      auth.currentUser.getIdToken().then((token) => {
        api.post(
          '/admin/add-admin',
          payload,
          { headers: { Authorization: `Bearer ${token}` } }
        ).then((response) => {
          if (!response.data.ok) {
            Vue.toasted.global.custom_error({ message: response.data.error })
            reject(response.data.error)
            return
          }
          // commit('ADD_ADMIN', response.data)
          dispatch('fetchAdminsAndSuper')
            .then(() => {
              commit('SET_IS_ADDING_ADMIN', false)
              resolve()
            }).catch((err) => {
              reject(err)
            })
        }).catch((err) => {
          reject(err)
        })
      }).catch((err) => {
        reject(err)
      })
    })
  },

  revokeAdmin({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      commit('SET_IS_REVOKING_ADMIN', true)
      auth.currentUser.getIdToken().then((token) => {
        api.post(
          '/admin/revoke-admin',
          payload,
          { headers: { Authorization: `Bearer ${token}` } }
        ).then((response) => {
          if (!response.data.ok) {
            Vue.toasted.global.custom_error({ message: response.data.error })
            reject(response.data.error)
            return
          }
          // commit('ADD_ADMIN', response.data)
          dispatch('fetchAdminsAndSuper')
            .then(() => {
              commit('SET_IS_REVOKING_ADMIN', false)
              resolve()
            }).catch((err) => {
              reject(err)
            })
        }).catch((err) => {
          reject(err)
        })
      }).catch((err) => {
        reject(err)
      })
    })
  },

  activateAdmin(_, payload) {
    return new Promise((resolve, reject) => {
      auth.currentUser.getIdToken().then((token) => {
        api.post(
          '/admin/activate-admin',
          payload,
          { headers: { Authorization: `Bearer ${token}` } }
        ).then((response) => {
          if (!response.data.ok) {
            Vue.toasted.global.custom_error({ message: response.data.error })
            reject(response.data.error)
            return
          }
          // commit('ADD_ADMIN', response.data)
          resolve()
        }).catch((err) => {
          reject(err)
        })
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async fetchPendingSpaceChanges(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/space-changes',
        {
          params,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchTransactions(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/transactions',
        {
          params,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchTransactionInfo(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/transaction/info',
        {
          params,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchTransaction(_, id) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/transaction',
        {
          params: { id },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async deleteTransaction(_, { id, cancelReason }) {
    const token = await auth.currentUser.getIdToken();
    
    try {
      const response = await api.delete(
        `/admin/transaction/${id}`,
        {
          params: { cancelReason },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async sendEmailTransaction(_, payload) {
    const token = await auth.currentUser.getIdToken();
    
    try {
      const response = await api.post(
        '/admin/transaction/send-mail',
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchSubscriptions(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/subscriptions',
        {
          params,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchEvent(_, id) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/event',
        {
          params: { id },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchEvents(_, query) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/events',
        {
          params: query,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async createTransaction(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/admin/transaction',
        payload,
        { headers: { Authorization: `Bearer ${token}` } },
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async updateTransaction(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.put(
        '/admin/transaction',
        payload,
        { headers: { Authorization: `Bearer ${token}` } },
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async updateSubscription(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.put(
        '/admin/subscription',
        payload,
        { headers: { Authorization: `Bearer ${token}` } },
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  fetchSpacesHabitatPass(_, params) {
    return new Promise((resolve, reject) => {
      auth.currentUser.getIdToken().then((token) => {
        api.get(
          '/admin/space/habitat-pass',
          {
            params,
            headers: { Authorization: `Bearer ${token}` },
          }
        ).then((response) => resolve(response.data))
        .catch(reject)
      })
      .catch(reject);
    });
  },

  fetchMonthlyIntegralRequests(_, params) {
    return new Promise((resolve, reject) => {
      auth.currentUser.getIdToken().then((token) => {
        api.get(
          '/admin/monthly-integral/request',
          {
            params,
            headers: { Authorization: `Bearer ${token}` },
          }
        ).then((response) => resolve(response.data))
        .catch(reject)
      })
      .catch(reject);
    });
  },

  editSpaceHabitatPass(_, params) {
    return new Promise((resolve, reject) => {
      auth.currentUser.getIdToken().then((token) => {
        api.post(
          '/admin/space/habitat-pass',
          params,
          { headers: { Authorization: `Bearer ${token}` } }
        ).then((response) => resolve(response.data))
        .catch(reject)
      })
      .catch(reject);
    });
  },

  async denySpaceHabitatPass(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/admin/space/habitat-pass/deny',
        params,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  validateSpace(_, payload) {
    return new Promise((resolve, reject) => {
      auth.currentUser.getIdToken().then((token) => {
        api.post(
          '/admin/validate-space-changes',
          payload,
          { headers: { Authorization: `Bearer ${token}` } }
        ).then((response) => resolve(response.data))
        .catch(reject)
      })
      .catch(reject);
    })
  },

  async anticipateTransfer(_, { transactionId }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/admin/transfer/anticipate',
        { transactionId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async exportData(_, query) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/export-data',
        {
          params: query,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async verifyUser(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/admin/user/verify',
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async transferReceived() {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/admin/transfer/received',
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async transferToBank() {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/admin/transfer/bank',
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async migrateData() {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/admin/migrate',
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async addProduct(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/admin/add/product',
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchProduct(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/product',
        { params: payload, headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async addCoupon(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/admin/coupon',
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchCoupons(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/coupons',
        {
          params: payload,
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async deleteCoupon(_, { id }) {
    const token = await auth.currentUser.getIdToken();
    
    try {
      const response = await api.delete(
        `/admin/coupon`,
        {
          params: { id },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async updateCoupon(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.put(
        '/admin/coupon',
        payload,
        { headers: { Authorization: `Bearer ${token}` } },
      );

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async addUser(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/admin/add/user',
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    } finally {
      // reset last user
      state.lastUser = null;
    }
  },

  async generatePasswordResetLink(_, email) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/admin/reset-password-link',
        {
          params: { email },
          headers: { Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    } finally {
      // reset last user
      state.lastUser = null;
    }
  },

  async sendTermsOfUse(_, userId) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/admin/user/terms-of-use',
        { userId },
        {
          headers: { Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    } finally {
      // reset last user
      state.lastUser = null;
    }
  },

  unsubscribeAll({ getters, commit }) {
    const prevUnsubscribers = getters.GET_UNSUBSCRIBERS;
    if (prevUnsubscribers && prevUnsubscribers.length > 0) {
      prevUnsubscribers.forEach((p) => p());
      commit(SET_UNSUBSCRIBERS, null);
    }
  },

  subscriberAll({ dispatch, commit, rootGetters }) {
    // reset previous unsubscribers if any
    dispatch('unsubscribeAll');

    const isAdminOrSuperAdmin = rootGetters['user/GET_IS_ADMIN_OR_SUPER_ADMIN'];

    if (!isAdminOrSuperAdmin) { return; }

    let queries = {
      spaces: firestoreDb
        .collection('metadata')
        .doc('spaces'),
      habitatPass: firestoreDb
        .collection('metadata')
        .doc('habitat-pass'),
      integral: firestoreDb
        .collection('metadata')
        .doc('integral'),
    };

    const unsubscribers = [];

    for (const key of Object.keys(queries)) {
      const query = queries[key];
      const unsubscriber = query
        .onSnapshot((snapshot) => {
          const onChange = (data) => {
            if (key === 'spaces') {
              commit(SET_ALL_PENDING_SPACES_COUNT, data ? data.pending : 0);
            } else if (key === 'habitatPass') {
              commit(SET_ALL_PENDING_HABITAT_PASS_COUNT, data ? data.pending : 0);
            } else if (key === 'integral') {
              commit(SET_ALL_PENDING_INTEGRAL_COUNT, data ? data.pending : 0);
            }
          };

          if (snapshot.docChanges) {
            snapshot
              .docChanges()
              .forEach((change) => {
                onChange({ id: change.doc.id, ...change.doc.data() }, change.type);
              });
          } else {
            onChange(snapshot.data());
          }
        })
        unsubscribers.push(unsubscriber);
    }

    commit(SET_UNSUBSCRIBERS, unsubscribers);
  },

  reset({ commit }) {
    commit(RESET);
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
