import { auth, firestoreDb } from '@/lib/firebase';
import api from '@/lib/api';

const SET_RECEIVERS = 'SET_RECEIVERS';
const SET_IS_LOADED = 'SET_IS_LOADED';
const SET_UNSUBSCRIBERS = 'SET_UNSUBSCRIBERS';

const GET_RECEIVERS = 'GET_RECEIVERS';
const GET_IS_LOADED = 'GET_IS_LOADED';
const GET_UNSUBSCRIBERS = 'GET_UNSUBSCRIBERS';
const RESET = 'RESET';

const getDefaultState = () => ({
  receivers: [],
  unsubscribers: [],
  isLoaded: false,
});

export const state = getDefaultState();

export const mutations = {
  [SET_RECEIVERS](state, data) {
    state.receivers = data;
  },
  [SET_IS_LOADED](state, isLoaded) {
    state.isLoaded = isLoaded;
  },
  [SET_UNSUBSCRIBERS](state, unsubscribers) {
    state.unsubscribers = unsubscribers;
  },

  [RESET](state) {
    if (state.unsubscribers) {
      state.unsubscribers.forEach((p) => p());
    }

    Object.assign(state, getDefaultState());
  },
}

export const getters = {
  [GET_RECEIVERS](state) {
    return state.receivers;
  },
  [GET_IS_LOADED](state) {
    return state.isLoaded;
  },
  [GET_UNSUBSCRIBERS](state) {
    return state.unsubscribers;
  }
}

export const actions = {
  async add(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/receivers',
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async orderTransfers(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/receivers/order/transfers',
        {
          params: payload,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async transactions(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/receivers/transactions',
        {
          params: payload,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async history(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/receivers/history',
        {
          params: payload,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async exportTransactions(_, query) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/receivers/transactions/export',
        {
          params: query,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async exportHistory(_, query) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/receivers/history/export',
        {
          params: query,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchBalance(_, { receiverId }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/receivers/balance',
        {
          params: { receiverId },
          headers: { Authorization: `Bearer ${ token }` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async rentability(_, { receiverId, year, month }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/receivers/rentability',
        {
          params: { receiverId, year, month },
          headers: { Authorization: `Bearer ${ token }` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async transfer(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/receivers/transfer',
        payload,
        { headers: { Authorization: `Bearer ${ token }` } }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data;

        if (errorMessage === 'transfer.not.done') {
          throw 'Apenas repasses finalizados podem ser transferidos';
        } else if (errorMessage === 'unauthorized') {
          throw 'Você não tem permissão para realizar esta ação';
        } else if (errorMessage === 'error.invalid.value') {
          throw 'Valor total inválido';
        }

        throw errorMessage;
      }

      throw error;
    }
  },

  async getDocumentStatus(_, { receiverId }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        'receivers/documents',
        {
          params: { receiverId },
          headers: { Authorization: `Bearer ${ token }` },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async updateDocuments(_, { receiverId, fileData, files }) {
    const formData = new FormData();
    files.forEach((f) => formData.append('files[]', f))
    formData.append('fileData', JSON.stringify(fileData));
    formData.append('receiverId', receiverId);

    const token = await auth.currentUser.getIdToken();
    
    try {
      const response = await api.post(
        '/receivers/documents',
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async delete({ getters, commit }, { receiverId }) {
    const token = await auth.currentUser.getIdToken();
    
    try {
      await api.delete(
        '/receivers/',
        {
          params: { receiverId },
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      const receivers = (getters[GET_RECEIVERS] || [])
        .filter((r) => r.id !== receiverId);

      commit(SET_RECEIVERS, [ ...receivers ]);

      return;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async transferOrderToBank(_, orderId) {
    const token = await auth.currentUser.getIdToken();
    
    try {
      const response = await api.post(
        '/receivers/order/transfer-to-bank',
        { orderId },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  unsubscribeAll({ getters, commit }) {
    const prevUnsubscribers = getters.GET_UNSUBSCRIBERS;
    if (prevUnsubscribers && prevUnsubscribers.length > 0) {
      prevUnsubscribers.forEach((p) => p());
      commit(SET_UNSUBSCRIBERS, null);
    }
  },

  subscriberAll({ dispatch, commit, getters, rootGetters }) {
    // reset previous unsubscribers if any
    dispatch('unsubscribeAll');

    const isAdminOrSuperAdmin = rootGetters['user/GET_IS_ADMIN_OR_SUPER_ADMIN'];
    const userInfo = rootGetters['user/GET_READONLY_INFO'];
    const userUid = rootGetters['user/GET_USER_UID'];
    const saasId = userInfo?.saasId;
    const paramsReceiver = saasId ? 'saasId' : 'userId';
    const valueToSearch = saasId ?? userUid;

    const queries = {
      'regular': firestoreDb
        .collection('receivers')
        .where(paramsReceiver, '==', valueToSearch)
        .where('isDeleted', '==', false),
      'admin': isAdminOrSuperAdmin ? firestoreDb
        .collection('receivers')
        .where('isAdmin', '==', true)
        .where('isDeleted', '==', false) : null,
    };
    const unsubscribers = [];

    for (const key of Object.keys(queries)) {
      const query = queries[key];

      if (!query) {
        continue;
      }

      const unsubscriber = query
        .onSnapshot((snapshot) => {
          const onChange = (data, type) => {
            const items = getters[GET_RECEIVERS] || [];

            if (type === 'modified') {
              console.log('receiver modified')
              const index = items.findIndex((i) => i.id === data.id);

              if (index < 0) { 
                console.warn('Receiver modified without being cached');
                return;
              }

              items[index] = data;
              commit(SET_RECEIVERS, [ ...items ]);
            } else if (type === 'added') {
              const index = items.findIndex((i) => i.id === data.id);

              if (index >= 0) {
                items[index] = data;
                commit(SET_RECEIVERS, [ ...items ]);
                return;
              }

              commit(SET_RECEIVERS, [ ...items, data ]);
            }
          };

          if (snapshot.docChanges) {
            snapshot
              .docChanges()
              .forEach((change) => {
                onChange({ id: change.doc.id, ...change.doc.data() }, change.type);
              });
          } else {
            onChange(snapshot.data());
          }

          commit(SET_IS_LOADED, true);
        });

      unsubscribers.push(unsubscriber);
    };

    commit(SET_UNSUBSCRIBERS, unsubscribers);
  },

  reset({ commit, dispatch }) {
    dispatch('unsubscribeAll');
    commit(RESET);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
