const apiKey = process.env.GOOGLE_GEOCODE_API_KEY
const loadTimeout = 30000

function loadAutoCompleteAPI() {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&language=pt-BR&v=weekly&region=BR&callback=googleMapsCallback`;
  document.querySelector('head').appendChild(script);
}

function googleMapsApiLoader() {
  return new Promise((resolve, reject) => {
    setTimeout(function () {
      if (!window.google) {
        reject(new Error('Loading took too long'));
      }
    }, loadTimeout);

    if (window.google) {
      return resolve(window.google);
    }

    window.googleMapsCallback = () => {
      resolve(window.google);
    }

    loadAutoCompleteAPI();
  });
}

module.exports = googleMapsApiLoader;
