import Vue from 'vue'
import store from '@/store'

export default function authAndVerifiedMiddleware(to, from, next) {
  const readonly = store.getters['user/GET_READONLY_INFO']
  const isOk = readonly && readonly.isSuperAdmin === true
  if (isOk) {
    next()
  } else {
    Vue.toasted.show('Você precisa ser super administrador para visualizar essa página.', {
      type : 'error',
      icon : 'alert-circle'
    })
    // const redirectTo = to.fullPath
    next({ name: 'owner-dashboard' })
  }
}
