'use strict';

import Cleave from 'cleave.js';

const buildDateMask = (ctx, ref, name, raw) => {
  const wrapper = ctx.$refs[ref];

  if (!wrapper) {
    return setTimeout(() => buildDateMask(ctx, ref, name, raw), 250);
  }

  if (wrapper && wrapper.$el) {
    const cleave = new Cleave(
      wrapper.$el.querySelector('input'),
      {
        date: true,
        datePattern: [ 'd', 'm', 'Y' ],
        copyDelimiter: true,
        onValueChanged: ({ target }) => {
          ctx.$nextTick(() => {
            ctx[name] = target.value;
            ctx.$emit('input', target.rawValue);
            ctx.$emit('change', target.rawValue);
          });
        },
      }
    );
    ctx.cleaveBinds[name] = cleave;
    ctx.$nextTick(() => cleave.setRawValue(raw || ''));
  }
};

const buildTimeMask = (ctx, ref, nameOrCb, raw) => {
  const wrapper = ctx.$refs[ref];

  if (!wrapper || Array.isArray(wrapper) && !wrapper.length) {
    return setTimeout(() => buildTimeMask(ctx, ref, nameOrCb, raw), 250);
  }

  const el = Array.isArray(wrapper) ? wrapper[0].$el : wrapper.$el;

  if (el) {
    const cleave = new Cleave(
      el.querySelector('input'),
      {
        time: true,
        timePattern: [ 'h', 'm' ],
        copyDelimiter: true,
        onValueChanged: ({ target }) => {
          ctx.$nextTick(() => {
            if (typeof nameOrCb === 'string') {
              ctx[nameOrCb] = target.value;
            } else {
              nameOrCb(target.value);
            }
            ctx.$emit('input', target.rawValue);
            ctx.$emit('change', target.rawValue);
          });
        },
      }
    );
    ctx.cleaveBinds[ref] = cleave;
    ctx.$nextTick(() => cleave.setRawValue(raw || ''));
  }
};

const buildNumberMask = (ctx, ref, nameOrCb, raw, length) => {
  const wrapper = ctx.$refs[ref];

  if (!wrapper || Array.isArray(wrapper) && !wrapper.length) {
    return setTimeout(() => buildNumberMask(ctx, ref, nameOrCb, raw, length), 250);
  }

  const el = Array.isArray(wrapper) ? wrapper[0].$el : wrapper.$el;

  if (el) {
    const options = {};

    if (length) {
      options.blocks = [ length ];
    }

    const cleave = new Cleave(
      el.querySelector('input'),
      {
        ...options,
        numericOnly: true,
        onValueChanged: ({ target }) => {
          ctx.$nextTick(() => {
            if (typeof nameOrCb === 'string') {
              ctx[nameOrCb] = target.value;
            } else {
              nameOrCb(target.value);
            }
            ctx.$emit('input', target.rawValue);
            ctx.$emit('change', target.rawValue);
          });
        },
      }
    );
    ctx.cleaveBinds[ref] = cleave;
    ctx.$nextTick(() => cleave.setRawValue(raw || ''));
  }
};

export {
  buildDateMask,
  buildNumberMask,
  buildTimeMask,
};
