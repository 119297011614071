import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted, {
  duration: 3000,
  theme: 'toasted-primary',
  iconPack : 'mdi',
  position: 'top-right',
  containerClass: 'toast-container',
});

// register the toast with the custom message
Vue.toasted.register('custom_error',
  (payload) => {
    // if there is no message passed show default message
    // if there is a message show it with the message
    return payload.message || 'Oops, ocorreu um problema!'
  },
  { type : 'error', icon : 'alert-circle' },
)
