import { auth, firestoreDb } from '@/lib/firebase';
import api from '@/lib/api';

export const MUTATIONS = {
  TERMS: 'TERMS',
  CURRENT: 'CURRENT',
  ACCEPTED: 'ACCEPTED',
  UNSUBSCRIBER: 'UNSUBSCRIBER',
};

export const GETTERS = {
  TERMS: 'TERMS',
  CURRENT: 'CURRENT',
  ACCEPTED: 'ACCEPTED',
  UNSUBSCRIBER: 'UNSUBSCRIBER',
};

const RESET = 'RESET';

const getDefaultState = () => ({
  terms: [],
  current: null,
  accepted: null,
  unsubscriber: null,
});

export const state = getDefaultState();

export const mutations = {
  [MUTATIONS.TERMS](state, terms) {
    state.terms = terms;
  },
  [MUTATIONS.CURRENT](state, current) {
    state.current = current;
  },
  [MUTATIONS.ACCEPTED](state, accepted) {
    state.accepted = accepted;
  },
  [MUTATIONS.UNSUBSCRIBER](state, unsubscriber) {
    state.unsubscriber = unsubscriber;
  },

  [RESET](state) {
    if (state.unsubscriber) {
      state.unsubscriber();
    }

    Object.assign(state, getDefaultState());
  },
}

export const getters = {
  [GETTERS.TERMS](state) {
    return state.terms;
  },
  [GETTERS.CURRENT](state) {
    return state.current;
  },
  [GETTERS.ACCEPTED](state) {
    return state.accepted;
  },
  [GETTERS.UNSUBSCRIBER](state) {
    return state.unsubscriber;
  },
}

export const actions = {
  add({ commit }, termFile) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', termFile);

      auth.currentUser.getIdToken().then((token) => {
        api.post(
          '/admin/terms-of-use',
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            },
          },
        )
        .then((response) => {
          const terms = response.data;
          commit(MUTATIONS.TERMS, terms);
          resolve(terms);
        })
        .catch(reject);
      })
      .catch(reject);
    });
  },

  accept({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      auth.currentUser.getIdToken().then((token) => {
        api.put(
          '/user/terms-of-use',
          null,
          { headers: { Authorization: `Bearer ${token}` } },
        )
        .then(() => {
          dispatch('user/fetchAcceptedTermsInfo', null, { root: true })
            .then(() => {
              commit(MUTATIONS.ACCEPTED, true);
              resolve();
            });
        })
        .catch(reject);
      })
      .catch(reject);
    });
  },

  verifyAcceptedTerms({ getters, commit, rootState, dispatch }) {
    const currentTerm = getters.CURRENT;

    if (!currentTerm) {
      return setTimeout(() => dispatch('verifyAcceptedTerms'), 500);
    }

    const lastAccepted = rootState.user.lastAcceptedTerm;

    if (!lastAccepted) {
      return setTimeout(() => dispatch('verifyAcceptedTerms'), 500);
    }

    commit(MUTATIONS.ACCEPTED, !currentTerm.version || currentTerm.version === lastAccepted.version);
  },

  loadCurrentTerm({ getters, commit, dispatch }) {
    const prevUnsubscriber = getters[GETTERS.UNSUBSCRIBER];
    
    if (prevUnsubscriber) {
      prevUnsubscriber();
      commit(MUTATIONS.UNSUBSCRIBER, null);
    }

    const unsubscriber = firestoreDb
      .collection('metadata')
      .doc('terms-of-use')
      .onSnapshot((snapshot) => {
        commit(MUTATIONS.CURRENT, snapshot.data());
        dispatch('verifyAcceptedTerms');
      });

    commit(MUTATIONS.UNSUBSCRIBER, unsubscriber);
  },

  loadAllTerms({ commit, dispatch, rootGetters }, { tryCount } = { tryCount: 0 }) {
    const isAdminOrSuperAdmin = rootGetters['user/GET_IS_ADMIN_OR_SUPER_ADMIN'];

    if (!isAdminOrSuperAdmin) {
      return tryCount < 3 && setTimeout(() => dispatch('loadAllTerms', { tryCount: (tryCount || 0) + 1 }), 250);
    }

    return new Promise((resolve, reject) => {
      firestoreDb
        .collection('terms-of-use')
        .orderBy('version', 'desc')
        .get()
        .then((snapshot) => {
          const terms = [];

          snapshot.docs.forEach((doc) => {
            const data = doc.data();
            terms.push({
              id: doc.id,
              ...data,
              createdAt: data.createdAt.toDate(),
            });
          });

          commit(MUTATIONS.TERMS, terms);
          resolve(terms);
        })
        .catch(reject);
    });
  },

  reset({ commit }) {
    commit(RESET);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
