<template>
  <v-text-field
    ref="inputRef"
    v-model="thisValue"
    v-mask="mask"
    v-bind="$attrs"
    v-on="$listeners"
    @input="onInput"
  >
    <template v-for="(_, slot) of $slots" v-slot:[slot]>
      <slot :name="slot">Default</slot>
    </template>
  </v-text-field>
</template>

<script>
  export default {
    name: 'DateInput',

    props: {
      value: String,
    },

    data() {
      return {
        thisValue: this.value || '',
        mask: this.dynamicMask,
      };
    },

    methods: {
      onInput(value) {
        this.$emit('input', value);
      },

      generateMask(value) {
        let groups = [
          value.slice(0, 2),
          value.slice(2, 4),
          value.slice(4, 8),
        ];
        let day = [ /[0-9]/, /[0-9]/ ];
        let month = [ /[0-1]/, /[0-2]/ ];
        let year = [ /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/ ];

        if (groups[0]) {
          if (groups[0].charAt(0) <= '2') {
            day = [ /[0-2]/, /[0-9]/ ];
          } else {
            day = [ '3', /[0-1]/ ];
          }
        }

        if (groups[1]) {
          if (groups[1].charAt(0) === '0') {
            month = [ /0/, /[0-9]/ ];
          } else if (groups[1].charAt(0) > '1') {
            month = [ '0', /[0-9]/ ];
          }
        }

        return value.length <= 2
          ? [ ...day ]
          : value.length <= 4
          ? [ ...day, '/', ...month ]
          : [ ...day, '/', ...month, '/', ...year ];
      },

      dynamicMask() {
        const numbers = this.thisValue.replace(/[^0-9]/g, '');
        return [ ...this.generateMask(numbers) ];
      },

      focus() {
        this.$refs.inputRef.focus();
      }
    },
    
    watch: {
      value(newValue) {
        // Disabling mask so we can input updated value
        this.mask = '';
        this.thisValue = newValue || '';
        // Enabling mask back
        this.$nextTick(() => this.mask = this.dynamicMask);
      }
    },
  };
</script>
