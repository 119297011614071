import { auth, firestoreDb, handleDoc, handleSnapshot } from '@/lib/firebase';
import { collections } from '@/constants/collections';
import api from '@/lib/api';
import DateHelper from '@/utils/dateHelper';

export const MUTATIONS = {
  MESSAGES: 'SET_MESSAGES',
  SYSTEM_MESSAGES: 'SET_SYSTEM_MESSAGES',
  UNSUBSCRIBERS: 'SET_UNSUBSCRIBERS',
};

export const GETTERS = {
  MESSAGES: 'GET_MESSAGES',
  SYSTEM_MESSAGES: 'GET_SYSTEM_MESSAGES',
  UNSUBSCRIBERS: 'GET_UNSUBSCRIBERS',
};

const RESET = 'RESET';

const getDefaultState = () => ({
  isBlurred: false,
  messages: [],
  systemMessages: [],
  unsubscribers: [],
});

export const state = getDefaultState();

export const mutations = {
  [MUTATIONS.MESSAGES](state, data) {
    state.messages = data;
  },
  [MUTATIONS.SYSTEM_MESSAGES](state, data) {
    state.systemMessages = data;
  },
  [MUTATIONS.UNSUBSCRIBERS](state, data) {
    state.unsubscribers = data;
  },

  [RESET](state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters = {
  [GETTERS.MESSAGES](state) {
    return state.messages;
  },
  [GETTERS.SYSTEM_MESSAGES](state) {
    return state.systemMessages;
  },
  [GETTERS.UNSUBSCRIBERS](state) {
    return state.unsubscribers;
  },
};

export const actions = {
  async fetchInternalMessagesTemplates(_, unityId) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/comms/messages/templates',
        {
          params: { unityId },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchInternalMessages(_, params) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/comms/messages',
        {
          params,
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async sendInternalMessage(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/comms/message',
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  
  },

  unsubscribeAll({ getters, commit }) {
    const prevUnsubscribers = getters[GETTERS.UNSUBSCRIBERS];

    if (prevUnsubscribers && prevUnsubscribers.length > 0) {
      prevUnsubscribers.forEach((p) => p());
      commit(MUTATIONS.UNSUBSCRIBERS, []);
    }
  },

  subscribeMessages({ commit, dispatch, getters }, {
    userId,
  }) {
    dispatch('unsubscribeAll');
    commit(MUTATIONS.MESSAGES, []);
    commit(MUTATIONS.SYSTEM_MESSAGES, []);

    const unsubscriber = firestoreDb
      .collection(collections.messages)
      .where('createdAt', '>=', DateHelper.now().add(-2, 'day').toDate())
      .where('fromId', '==', userId)
      .orderBy('createdAt', 'desc')
      .onSnapshot((snapshot) => {
        const onChange = (data, type) => {
          const messages = getters[GETTERS.MESSAGES] || [];

          if (type === 'added') {
            commit(MUTATIONS.MESSAGES, [ data, ...messages ]);
          } else if (type === 'modified') {
            commit(MUTATIONS.MESSAGES, messages.map((m) => (m.id === data.id ? data : m)));
          } else if (type === undefined) {
            commit(MUTATIONS.MESSAGES, [ ...data, ...messages ]);
          }
        };

        if (snapshot.docChanges) {
          snapshot
            .docChanges()
            .forEach((change) => {
              onChange(handleDoc(change.doc), change.type);
            });
        } else {
          onChange(handleSnapshot(snapshot));
        }
      });

    const systemUnsubscriber = firestoreDb
      .collection(collections.systemMessages)
      .orderBy('createdAt', 'desc')
      .onSnapshot((snapshot) => {
        const onChange = (data, type) => {
          const messages = getters[GETTERS.SYSTEM_MESSAGES] || [];

          if (type === 'added') {
            commit(MUTATIONS.SYSTEM_MESSAGES, [ data, ...messages ]);
          } else if (type === 'modified') {
            commit(MUTATIONS.SYSTEM_MESSAGES, messages.map((m) => (m.id === data.id ? data : m)));
          } else if (type === undefined) {
            commit(MUTATIONS.SYSTEM_MESSAGES, [ ...data, ...messages ]);
          }
        };

        if (snapshot.docChanges) {
          snapshot
            .docChanges()
            .forEach((change) => {
              onChange(handleDoc(change.doc), change.type);
            });
        } else {
          onChange(handleSnapshot(snapshot));
        }
      });

    commit(MUTATIONS.UNSUBSCRIBERS, [unsubscriber, systemUnsubscriber]);
  },

  reset({ commit, dispatch }) {
    dispatch('unsubscribeAll');
    commit(RESET);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
