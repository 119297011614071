import { Capacitor } from '@capacitor/core';
import { Facebook } from '@awesome-cordova-plugins/facebook';

import { analytics, firestoreDb } from '@/lib/firebase';
import { collections } from '@/constants/collections';
import events from '@/constants/events';
import store from '../store/index';

class CompanyAnalytics {

  async logEvent(event, params) {
    if ([events.page_view, events.login].includes(event)) {
      return;
    }

    try {
      const userId = store.getters['user/GET_USER_UID'];
      const isAdminOrSuperAdmin = store.getters['user/GET_IS_ADMIN_OR_SUPER_ADMIN'];

      if (isAdminOrSuperAdmin) {
        return;
      }
      
      await firestoreDb
        .collection(collections.analytics)
        .add({
          event,
          params,
          userId: userId || null,
          timestamp: new Date(),
        });
    } catch (error) {
      // DO NOTHING
    }
  }

  setUserProperties() {
    // DO NOTHING
  }
}

class FirebaseAnalytics {

  logEvent(event, params) {
    analytics.logEvent(event, params);
  }

  setUserProperties(params) {
    analytics.setUserProperties(params);
  }
}

class FBAnalytics {

  async logEvent(event, params) {
    await Facebook.logEvent(event, params);
  }

  setUserProperties() {
    // DO NOTHING
  }
}

class Analytics {

  constructor() {
    const analytics = [
      new CompanyAnalytics(),
      new FirebaseAnalytics(),
    ];

    if (Capacitor.isNativePlatform()) {
      analytics.push(new FBAnalytics());
    }

    this.analytics = analytics;
  }

  logEvent(event, params) {
    this.analytics.forEach((analytics) => analytics.logEvent(event, params));
  }

  setUserProperties(params) {
    this.analytics.forEach((analytics) => analytics.setUserProperties(params));
  }

};


export default new Analytics;