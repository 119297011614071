import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';

import AuthService from '@/services/AuthService';
import store from '@/store';

class PushNotification {

  async addListeners() {
    await PushNotifications.addListener('registration', async (token) => {
      console.info('Registration token: ', token.value);

      await AuthService.isInitialized();

      store.dispatch('user/fcmTokenUpdated', {
        plataform: Capacitor.getPlatform(),
        token: token.value,
      });
    });

    await PushNotifications.addListener('registrationError', (err) => {
      console.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener('pushNotificationReceived', (notification) => {
      console.log('Push notification received: ', notification);
    });

    await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
      console.log('Push notification action performed', notification.actionId, notification.inputValue);
    });
  }

  async registerNotificationsIfPossible() {
    const permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive !== 'granted') {
      console.log('User denied notifications permissions');
      return;
    }

    await PushNotifications.register();
  }

  async registerNotifications() {
    const permStatus = await PushNotifications.requestPermissions();

    if (permStatus.receive !== 'granted') {
      console.log('User denied notifications permissions');
      return;
    }

    await PushNotifications.register();
  }

  async unregister() {
    await PushNotifications.unregister();
  }

};

export default new PushNotification();
