import { auth, firestoreDb } from '@/lib/firebase'

auth.onAuthStateChanged((user) => {
  // NOTE: if user is not logged in, we should NOT use conpass
  // It will create a random user and count against our quota!
  if (user) {
    // signed in
    firestoreDb.collection('users').doc(user.uid).get()
      .then((doc) => {
        const userInfo = doc.data()
        let count = 0
        // avoid loop when undefined is passed for name
        if (userInfo && userInfo.name) {
          const ConpassInterval = window.setInterval(() => {
            if (window.Conpass) {
              window.Conpass.init({
                name: userInfo.name,
                email: userInfo.email,
                // Informações opcionais (usado para segmentação)
                custom_fields: {
                  lang: 'pt_BR',
                  fakeFieldForTests: true,
                  sex: userInfo.sex,
                  treatment: userInfo.treatment,
                  birthday: userInfo.birthday,
                  phone: userInfo.phone,
                  registrationNumber: userInfo.registrationNumber,
                  createdAt: userInfo.createdAt,
                  updatedAt: userInfo.updatedAt,
                }
              })
              // initially count was 100 and interval was 100ms
              // increased interval and reduced count for a less aggressive polling
              if (window.Conpass || count >= 50) {
                clearInterval(ConpassInterval)
              }
            }
            count += 1
          }, 200)
        }
      }).catch((err) => {
        console.error(`Error when setting up Conpass: ${err}`)
        // we do not throw here: Conpass is not essential...
      })
  }
})
