import { Capacitor } from '@capacitor/core';

// This import loads the firebase namespace.
import firebase from 'firebase/compat/app';

// These imports load individual services into the firebase namespace.
import { initializeAuth, indexedDBLocalPersistence, getAuth, connectAuthEmulator } from 'firebase/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import 'firebase/compat/analytics';

// TODO use env file
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const isDev = process.env.NODE_ENV === 'development';
const useEmulator = isDev && process.env.USE_EMULATOR === 'true';
const projectId = process.env.PROJECT_ID;

// TODO use env file
const firebaseConfig = {
  projectId,
  apiKey: process.env.API_KEY,
  authDomain: `${ projectId }.firebaseapp.com`,
  databaseURL: `https://${ projectId }.firebaseio.com`,
  storageBucket: `${ projectId }.appspot.com`,
  messagingSenderId: '91207947437',
  appId: process.env.APP_ID,
  measurementId: process.env.MEASUREMENT_ID,
};

const firebaseApp = firebase.initializeApp(firebaseConfig)

function whichAuth() {
  if (Capacitor.isNativePlatform()) {
    return initializeAuth(firebaseApp, {
      persistence: indexedDBLocalPersistence
    });
  }
  
  return getAuth();
}

const storage = firebase.storage();
const analytics = firebase.analytics();
const auth = whichAuth();
auth.languageCode = 'pt_br';

const firebaseCommonErrors = {
  'auth/invalid-login-credentials': 'Usuário ou senha incorretos',
  'auth/user-not-found': 'Usuário ou senha incorretos',
  'auth/wrong-password': 'Usuário ou senha incorretos',
  'auth/email-already-in-use': 'E-mail já em uso. Use o espaço de login para se conectar',
  'auth/email-already-exists': 'E-mail já em uso',
  'auth/popup-closed-by-user': 'Popup de login fechado pelo usuário',
  'auth/popup-blocked': 'Popup de login bloqueado pelo navegador. Por favor desbloqueie e tente novamente',
}

const firestoreDb = firebase.firestore()

const functionsRegion = 'southamerica-east1'
const firebaseFunctions = firebase.app().functions(functionsRegion)
const functionsEmulatorPort = 5001
const functionsUrl = useEmulator
  ? `http://localhost:${functionsEmulatorPort}/${projectId}/${functionsRegion}/api`
  : `https://${functionsRegion}-${projectId}.cloudfunctions.net/api`;


if (useEmulator) {
  connectAuthEmulator(auth, 'http://localhost:9099');
  firestoreDb.useEmulator('localhost', 8080)
  firebaseFunctions.useEmulator('localhost', functionsEmulatorPort)
  storage.useEmulator('localhost', 9199);
}

const handleCount = (doc) => doc.data().count;
const handleDoc = (doc) => (doc.exists ? { id: doc.id, ...doc.data() } : null);
const handleSnapshot = (snapshot) => snapshot.docs.map(handleDoc);

export {
  analytics,
  auth,
  firebase,
  firestoreDb,
  firebaseCommonErrors,
  firebaseFunctions,
  functionsUrl,
  handleDoc,
  handleCount,
  handleSnapshot,
};
