<template>
  <v-text-field
    ref="inputRef"
    v-model="thisValue"
    v-mask="mask"
    v-bind="$attrs"
    v-on="$listeners"
    @input="onInput"
  />
</template>

<script>
  export default {
    name: 'TimeInput',

    props: {
      value: String,
    },

    data() {
      return {
        thisValue: this.value || '',
        mask: this.dynamicMask,
      };
    },

    methods: {
      onInput(value) {
        this.$emit('input', value);
      },

      timeMask(value) {
        let hours;
        let minutes = [/[0-5]/, /[0-9]/];

        if (value.charAt(0) > '2') {
          hours = [0, /[0-9]/];
        } else {
          hours = [
            /[0-2]/,
            value.charAt(0) === '2' ? /[0-3]/ : /[0-9]/,
          ];
        }

        if (value.charAt(2) === ':') {
          if (value.charAt(3) > '5') {
            minutes = [0, /[0-9]/];
          }
        } else if (value.charAt(2) > '5') {
          minutes = [0, /[0-9]/];
        }

        return value.length > 2
          ? [...hours, ':', ...minutes]
          : hours;
      },

      dynamicMask() {
        const numbers = this.thisValue.replace(/[^0-9:]/g, '');
        return [ ...this.timeMask(numbers) ];
      },

      focus() {
        this.$refs.inputRef.focus();
      }
    },
    
    watch: {
      value(newValue) {
        // Disabling mask so we can input updated value
        this.mask = '';
        this.thisValue = newValue || '';
        // Enabling mask back
        this.$nextTick(() => this.mask = this.dynamicMask);
      }
    }
  };
</script>
