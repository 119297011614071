<template>
  <v-app>
    <v-app-bar height="96" :color="transparentToolbar ? 'transparent' : 'white'" :flat="transparentToolbar" absolute app>
      <div class="flex justify-center h-full">
        <div class="flex items-center fill-height max-w-[90rem] mx-10">
          <!-- <img src="/icon.png" alt="logo" class="nav-logo" @click="goToHome"> -->
          <v-toolbar-title class="min-w-0 h-full flex items-center flex-1">
            <!-- <v-img src="/icon.png" max-height="120" max-width="180" contain></v-img> -->
            <img 
              alt="logo"
              class="max-w-[186px] max-h-[56px]" 
              :src="logoUrl" 
              @click="goToHome"
            >
          </v-toolbar-title>

          <div class="grow-0 shrink-0 basis-auto hidden lg:block" v-if="isHome || isSaasHome">
            <div class="flex flex-row justify-center text-center gap-5" v-if="!isSaas || isSaasHome">
              <a
                class="!text-[22px] font-bold mx-4"
                :class="selectedClass(tab)"
                :key="`tab-menu-${index}`"
                @click="() => tab.anchor ? scrollToAnchor(tab.anchor) : openLink(tab.href, tab.internal)"
                v-for="(tab, index) in currentTabsMenu"
              >
                {{ tab.title }}
              </a>
            </div>
          </div>

          <div class="flex-1 justify-end hidden lg:flex" v-if="!hideButtons">
            <v-btn
              elevation="0"
              class="text-subtitle-1 white--text min-w-[94px]"
              :class="isSaasHome ? 'new-secondary' : 'new-primary'"
              :small="isSmall"
              @click="goToSignIn"
              rounded
              v-if="!isLogged"
            >
              <strong>Entrar</strong>
            </v-btn>

            <v-btn
              class="text-subtitle-1 secondary"
              elevation="0"
              :to="backToDashboardPath"
              rounded
              small
              v-if="isLogged && $isApp"
            >
              <strong>Meu espaço</strong>
            </v-btn>

            <v-btn
              elevation="0"
              class="w-[80px] rounded-full min-w-[94px]"
              :color="isSaasHome ? 'new-secondary' : 'new-primary'"
              :to="backToDashboardPath"
              v-else-if="isLogged"
            >
              <v-icon size="24" color='white' :style="{marginRight: '10px'}">mdi-menu</v-icon>

              <v-avatar size="24">
                <v-img :alt="username" :src="profileImage" v-if="profileImage">
                  <template v-slot:placeholder>
                    <v-icon size="24" color='white'>mdi-account-circle</v-icon>
                  </template>
                </v-img>

                <v-icon size="24" color='white' v-else>mdi-account-circle</v-icon>
              </v-avatar>
            </v-btn>
          </div>
        </div>
      </div>

      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="block lg:hidden mr-10" />
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" right app temporary>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              Habitat Consultórios
            </v-list-item-title>
            <v-list-item-subtitle>
              Consultórios Inteligentes
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4" mandatory>
          <v-list-item link to="/">
            <v-list-item-icon>
              <v-icon>
                mdi-home
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Início
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-divider></v-divider>

      <v-list v-if="isLogged" dense>
        <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4" mandatory>
          <v-list-item link :to="backToDashboardPath">
            <v-list-item-icon>
              <v-icon>
                mdi-apps
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Voltar ao meu espaço
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="signout">
            <v-list-item-icon>
              <v-icon>
                mdi-exit-to-app
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Sair
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-list v-else dense>
        <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4" mandatory>
          <v-list-item link to="/login">
            <v-list-item-icon>
              <v-icon>
                mdi-login
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                Entrar
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link to="/signup">
            <v-list-item-icon>
              <v-icon>
                mdi-account-plus
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                Cadastre-se
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            to="/saas"
            link
            v-if="!isSaasHome && !isApp"
          >
            <v-list-item-icon>
              <v-icon>
                mdi-domain
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                Sou proprietário
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            to="/"
            link
            v-if="isSaasHome && !isApp"
          >
            <v-list-item-icon>
              <v-icon>
                mdi-medical-bag
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                Sou profissional de saúde
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>
        <!-- <v-subheader>Profissional</v-subheader> -->
        <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4" mandatory>
          <v-list-item v-for="(tab, index) in tabs" :key="`sidebar-item-${index}`" :to="tab.to" :disabled="!tab.ready" link>
            <v-list-item-icon>
              <v-icon v-if="tab.icon"> {{ tab.icon }} </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ tab.title }}
                <v-chip v-if="!tab.ready" color="primary" class="ml-2" small dark outlined>
                  Em breve
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main v-if="withContainer">
      <v-container class="mt-4">
        <slot>
        </slot>
      </v-container>
    </v-main>
    <slot v-else>
    </slot>

    <terms-of-use-dialog :show="canShowTerms" :on-cancel="() => isShowingTerms = false" :closeable="false"/>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { IS_APP } from '@/constants';
import ViewportMixin from '@/mixins/ViewportMixin.js';
import TermsOfUseDialog from '@/components/TermsOfUseDialog.vue';

export default {
  name: 'ToolbarLayout',

  mixins: [
    ViewportMixin,
  ],

  components: {
    TermsOfUseDialog,
  },

  props: {
    withContainer: {
      type: Boolean,
      default: false,
    },
    transparentToolbar: {
      type: Boolean,
      default: false,
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
    isSaasHome: {
      type: Boolean,
      default: false,
    },
    isSaasPrices: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      isApp: IS_APP,

      tabsMenu: [
        {
          id: 'item-home-professional',
          title: 'Home',
          anchor: '',
          internal: true,
        },
        {
          id: 'item-steps',
          title: 'Como funciona',
          anchor: 'steps',
        },
        {
          id: 'item-about',
          title: 'Sobre',
          anchor: 'about',
        },
        {
          id: 'item-blog',
          title: 'Blog',
          href: 'https://blog.habitatconsultorios.com.br',
          internal: false,
        },
        {
          id: 'item-owner',
          title: 'Sou proprietário',
          href: '/saas',
          internal: true,
        },
      ],

      saasTabsMenu: [
        {
          id: 'item-home',
          title: 'Home',
          anchor: '',
          href: '/saas',
          internal: true,
        },
        // {
        //   id: 'item-prices',
        //   title: 'Preços',
        //   href: '/saas/prices/',
        //   internal: true
        // },
        {
          id: 'item-professional',
          title: 'Sou profissional de saúde',
          href: '/',
          internal: true,
        },
      ],

      tabs: [
        // { title: 'Consultório para sua especialidade', icon: 'mdi-domain', to: '/owner-calendar', role: 'owner', ready: true },
        // { title: 'Eventos de saúde', icon: 'mdi-domain', to: '/owner-spaces', role: 'owner', ready: true },
        {
          title: 'Blog',
          icon: 'mdi-rss',
          to: this.$isApp ? null : '/blog',
          href: this.$isApp ? 'https://blog.habitatconsultorios.com.br/' : null,
          target: this.$isApp ? '_blank' : null,
          ready: true
        },
      ],
      // NOTE: Using null as the starting value for its v-model will initialize the drawer as closed on mobile and as open on desktop
      drawer: false,
      group: null,
      isShowingTerms: false,
      isHome: false,
    }
  },

  computed: {
    ...mapGetters('user', {
      isLogged: 'IS_CONNECTED_AND_VERIFIED',
      preferences: 'GET_PREFERENCES',
      readonlyInfo: 'GET_READONLY_INFO',
      userInfo: 'GET_USER_INFO',
    }),
    ...mapGetters('termsOfUse', { termsAccepted: 'ACCEPTED' }),
    ...mapGetters('app', {
      appConfig: 'GET_CONFIG',
    }),
    ...mapGetters('subscription', {
      saasSubscription: 'GET_SAAS_SUBSCRIPTION',
    }),

    currentTabsMenu() {
      return this.isSaasHome ? this.saasTabsMenu : this.tabsMenu;
    },

    logoUrl() {
      return this.appConfig?.saasLogo ?? '/icon.png';
    },

    initials() {
      return this.userInfo?.name?.split(' ').slice(0, 2).map(s => s.charAt(0)).join('') ?? null;
    },

    username() {
      return this.userInfo?.name.split(' ').slice(0, 2).join(' ')?.trim() ?? '';
    },

    profileImage() {
      return this.userInfo?.profileImageUrl;
    },

    backToDashboardPath() {
      if (this.$isApp || this.preferences?.useDoctorDashboard) {
        return '/doctor/dashboard';
      }

      if (this.isSaasTeam || this.isSaasSubscriptionActive) {
        return '/owner/saas/dashboard';
      }

      return '/owner-dashboard';
    },

    isSmall() {
      return this.$vuetify.breakpoint.sm
          || this.$vuetify.breakpoint.xs;
    },

    canShowTerms() {
      if (!this.readonlyInfo) {
        return false;
      }

      return this.isLogged
          && this.isShowingTerms
          && !this.readonlyInfo.mustSetPassword
          && !this.isHome;
    },

    saasId() {
      return this.appConfig?.saasId;
    },

    isSaas() {
      return !!this.saasId;
    },

    isSaasTeam() {
      return !!this.saasId && this.readonlyInfo?.saasRole !== 'user';
    },

    isSaasSubscriptionActive() {
      return this.saasSubscription?.status === 'active';
    },
  },

  methods: {
    ...mapActions('user', ['signout']),

    goToHome() {
      this.$router.push({ name: 'home' });
    },

    goToSignIn() {
      this.$router.push({ name: 'login', query:  this.$route.query  });
    },

    scrollToAnchor(anchor) {
      const el = document.getElementById(anchor);
      el?.scrollIntoView({ behavior: 'instant' });
    },

    openLink(link, internal) {
      if (internal) {
        this.$router.push(link);
      } else {
        window.open(link, '_blank');
      }
    },

    selectedClass(tab) {
      if ((tab.id === 'item-home' && !this.isSaasPrices) || (tab.id === 'item-home-professional' && this.isHome)) {
        return `font-bold ${this.isSaasHome ? 'new-secondary--text' : 'new-primary--text'}`;
      } else if (tab.id === 'item-prices' && this.isSaasPrices) {
        return 'font-bold new-secondary--text';
      } else {
        return 'new-black--text';
      }
    },
  },

  watch: {
    group() {
      if (this.getIsNarrowerThanSm()) {
        // mobile
        this.drawer = false
      }
    },

    termsAccepted: {
      handler() {
        this.isShowingTerms = this.termsAccepted === false;
      },
      immediate: true,
    },

    '$route': {
      handler() {
        this.isHome = this.$router.currentRoute?.name === 'home' || this.$router.currentRoute?.name === 'search';
      },
      immediate: true,
    }
  },
}
</script>

<style lang="scss" scoped>
.v-toolbar__title {
  overflow: visible;
  display: flex;
}

::v-deep .v-toolbar__content {
  padding: 4px 0px;
}
</style>

<style lang="scss">
.v-application--wrap {
  margin-top: env(safe-area-inset-top);
}

.v-navigation-drawer__content {
  padding-top: env(safe-area-inset-top);
}
</style>
