import { auth, firestoreDb } from '@/lib/firebase'
import { notify } from '@/lib/notifications';
import api from '@/lib/api';
import doctorValidatorSteps from '@/constants/doctorValidatorSteps';

const GET_OWN_DOCTOR_VALIDATION = 'GET_OWN_DOCTOR_VALIDATION';
const GET_ALL_DOCTORS_VALIDATIONS_COUNT = 'GET_ALL_DOCTORS_VALIDATIONS_COUNT';
const GET_UNSUBSCRIBERS = 'GET_UNSUBSCRIBERS';

const SET_OWN_DOCTOR_VALIDATION = 'SET_OWN_DOCTOR_VALIDATION';
const SET_ALL_DOCTORS_VALIDATIONS_COUNT = 'SET_ALL_DOCTORS_VALIDATIONS_COUNT';
const SET_UNSUBSCRIBERS = 'SET_UNSUBSCRIBERS';

const RESET = 'RESET';

const getDefaultState = () => ({
  ownDoctorValidation: null,
  allDoctorsValidations: {},
  allDoctorsValidationsCount: 0,
  unsubscribers: [],
});

export const state = getDefaultState();

export const mutations = {
  [SET_OWN_DOCTOR_VALIDATION](state, ownDoctorValidation) {
    state.ownDoctorValidation = { ...ownDoctorValidation };
  },
  [SET_ALL_DOCTORS_VALIDATIONS_COUNT](state, count) {
    state.allDoctorsValidationsCount = count;
  },
  [SET_UNSUBSCRIBERS](state, unsubscribers) {
    state.unsubscribers = unsubscribers
  },

  [RESET](state) {
    if (state.unsubscribers) {
      state.unsubscribers.forEach((u) => u());
    }

    Object.assign(state, getDefaultState());
  },
}

export const getters = {
  [GET_OWN_DOCTOR_VALIDATION](state) {
    return state.ownDoctorValidation;
  },
  [GET_ALL_DOCTORS_VALIDATIONS_COUNT](state) {
    return state.allDoctorsValidationsCount;
  },
  [GET_UNSUBSCRIBERS](state) {
    return state.unsubscribers;
  },
}

export const actions = {
  async fetch(_, query) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/validate-user',
        {
          params: query,
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      return response.data;
    } catch (error) {
      if (error.response.data) {
        throw error.response.data;
      }
    }
  },

  async fetchDoctors(_, query) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/validate-user/doctors',
        {
          params: {
            ...query,
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      return response.data;
    } catch (error) {
      if (error.response.data) {
        throw error.response.data;
      }
    }
  },

  updateDoctor(_, payload) {
    return new Promise((resolve, reject) => {
      auth.currentUser.getIdToken().then((token) => {
        api.post(
          '/validate-user/doctor',
          { ...payload },
          { headers: { Authorization: `Bearer ${token}` } },
        )
        .then((response) => resolve(response.data))
        .catch(reject);
      })
      .catch(reject);
    })
  },

  validateDoctor(_, { validationId }) {
    return new Promise((resolve, reject) => {
      auth.currentUser.getIdToken().then((token) => {
        api.post(
          '/validate-user/doctor/validate',
          { validationId },
          { headers: { Authorization: `Bearer ${token}` } },
        )
        .then((response) => resolve(response.data))
        .catch(reject);
      })
      .catch(reject);
    })
  },

  // User

  async updateDoctorDocs({ dispatch }, { files, filenames, userId, specFiles }) {
    const token = await auth.currentUser.getIdToken();
    const uploadDoc = async (file, name, isSpec) => {
      const formData = new FormData();
      formData.append('userId', userId);
      formData.append('file', file);
      formData.append('filename', name);
      formData.append('isSpec', isSpec);

      try {
        const response = await api.post(
          '/validate-user/doctor/doc',
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            },
          },
        );

        return response.data;
      } catch (error) {
        if (error.response) {
          throw error.response.data;
        }

        throw error;
      }
    }

    let promise = Promise.resolve();

    files.forEach((file) => {
      promise = promise.then(() => uploadDoc(file, filenames[file.name], false));
    });

    specFiles.forEach((file) => {
      promise = promise.then(() => uploadDoc(file, filenames[file.name], true));
    });

    // Wait all docs to be uploaded
    await promise;

    // Then check if necessary docs exists
    await dispatch('validateUser/checkDocs', { userId }, { root: true });
  },

  async checkDocs({ commit, rootGetters }, { userId }) {
    const token = await auth.currentUser.getIdToken();
    const uid = rootGetters['user/GET_USER_UID'];
    const isAdminOrSuperAdmin = rootGetters['user/GET_IS_ADMIN_OR_SUPER_ADMIN'];

    if (!isAdminOrSuperAdmin && uid !== userId) {
      throw new Error('error.user.not.unauthorized');
    }

    try {
      const response = await api.post(
        '/validate-user/doctor/check-docs',
        { userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (uid === userId) {
        commit(SET_OWN_DOCTOR_VALIDATION, {
          ...getters.GET_OWN_DOCTOR_VALIDATION,
          ...response.data,
        });
      }

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  updateDoctorSignedContract(_, { files }) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      files.forEach((f) => formData.append('files[]', f))

      auth.currentUser.getIdToken().then((token) => {
        api.post(
          '/validate-user/doctor/contract',
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            },
          },
        )
        .then((response) => resolve(response.data))
        .catch(reject);
      })
      .catch(reject);
    })
  },

  markAsRead({ commit, getters }) {
    if (!getters.GET_OWN_DOCTOR_VALIDATION) { return; }
    commit(SET_OWN_DOCTOR_VALIDATION, {
      ...getters.GET_OWN_DOCTOR_VALIDATION,
      isRead: true,
    });
  },

  // Other

  downloadFile(_, { userId, validationId, filename, isSpec }) {
    return new Promise((resolve, reject) => {
      auth.currentUser.getIdToken().then((token) => {
        api.get(
          '/validate-user/doctor/download-file',
          {
            params: {
              userId,
              isSpec,
              filename,
              validationId,
            },
            responseType: 'blob',
            headers: { Authorization: `Bearer ${token}` },
            'Content-Disposition': `attachment;filename=${ filename }`
          },
        )
        .then((response) => resolve(response.data))
        .catch(reject);
      })
      .catch(reject);
    })
  },

  loadDoctorValidations({ dispatch, getters, commit, rootGetters }) {
    // reset previous unsubscribers if any
    const prevUnsubscribers = getters.GET_UNSUBSCRIBERS;
    if (prevUnsubscribers && prevUnsubscribers.length > 0) {
      prevUnsubscribers.forEach((p) => p());
      commit(SET_UNSUBSCRIBERS, null);
    }

    const myId = rootGetters['user/GET_USER_UID'];
    const isAdminOrSuperAdmin = rootGetters['user/GET_IS_ADMIN_OR_SUPER_ADMIN'];

    let queries = {
      own: firestoreDb
        .collection('validations')
        .where('userId', '==', myId),
    };

    if (isAdminOrSuperAdmin) {
      queries.all = firestoreDb
        .collection('metadata')
        .doc('validations');
    }

    const unsubscribers = [];

    for (const key of Object.keys(queries)) {
      const query = queries[key];
      const unsubscriber = query
        .onSnapshot((snapshot) => {
          const onChange = (data, type) => {
            if (key === 'all') {
              commit(SET_ALL_DOCTORS_VALIDATIONS_COUNT, data ? data.count : 0);
            } else if (key == 'own') {
              let isRead = true;

              if (type === 'modified') {
                if (data.isPending) {
                  const currentValidation = getters.GET_OWN_DOCTOR_VALIDATION;
                  const currentStep = currentValidation.step;
                  const shouldSkip = [doctorValidatorSteps.waitDocs, doctorValidatorSteps.waitContract].includes(currentStep);

                  if (!shouldSkip && [doctorValidatorSteps.waitDocs, doctorValidatorSteps.waitContract].includes(data.step)) {
                    isRead = false;
                    notify(`Habitat:`, 'Houve uma atualização na validação do seu Perfil Profissional');
                  }
                } else {
                  dispatch('user/fetchReadonlyInfo', null, { root: true });
                }
              }

              commit(SET_OWN_DOCTOR_VALIDATION, {
                ...getters.GET_OWN_DOCTOR_VALIDATION,
                ...data,
                isRead,
              });
            }
          };

          if (snapshot.docChanges) {
            const changes = snapshot.docChanges();

            if (changes.length) {
              snapshot
                .docChanges()
                .forEach((change) => {
                  onChange({ id: change.doc.id, ...change.doc.data() }, change.type);
                });
            } else if (key === 'own') {
              onChange({ step: 'init' }, 'add');
            }
          } else {
            onChange(snapshot.data());
          }
        })
        unsubscribers.push(unsubscriber);
    }

    commit(SET_UNSUBSCRIBERS, unsubscribers);
  },

  reset({ commit }) {
    commit(RESET);
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
