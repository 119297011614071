import Vue from 'vue';
import axios from 'axios';
import { functionsUrl, auth } from '@/lib/firebase';
import router from '@/router';

const api = axios.create({
  baseURL: functionsUrl,
})

api.interceptors.response.use((response) => response, (error) => {
  // if (error.response && error.response.data) {
  //   Vue.toasted.global.custom_error({ message: error.response.data })
  // }
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response && error.response.data) {
    if (error.response.data === 'invalid.auth') {
      auth.signOut();
      router.push({ path: '/' });
      Vue.toasted.show('Sua sessão expirou', {
        type : 'error',
        icon : 'alert-circle'
      });
    }
  }
  return Promise.reject(error)
})

export default api

// const isDev = process.env.NODE_ENV === 'development'
// axios.defaults.headers.post['Content-Type'] = 'application/json'
// axios.defaults.headers.timeout = isDev ? 5000 : 15000
// axios.defaults.withCredentials = true
// axios.defaults.xsrfHeaderName = 'X-CSRF-TOKEN'
// axios.defaults.xsrfCookieName = '_csrf'

// import Cookies from 'js-cookie'
// axios.defaults.headers.common['csrf-token'] = Cookies.get('csrfToken')

// Not for GET, HEAD or OPTIONS
// const csrf_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
// axios.defaults.headers.post['csrf-token'] = csrf_token
// axios.defaults.headers.put['csrf-token'] = csrf_token
// axios.defaults.headers.delete['csrf-token'] = csrf_token
// axios.defaults.headers.patch['csrf-token'] = csrf_token
// Axios does not create an object for TRACE method by default, and has to be created manually.
// axios.defaults.headers.trace = {}
// axios.defaults.headers.trace['csrf-token'] = csrf_token
