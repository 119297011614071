<template>
  <v-text-field
    ref="inputRef"
    v-model="thisValue"
    v-mask="mask"
    v-bind="$attrs"
    v-on="$listeners"
    @input="onInput"
  />
</template>

<script>
  export default {
    name: 'PhoneInput',

    props: {
      value: String,
    },

    data() {
      return {
        thisValue: this.value,
        mask: this.dynamicMask,
      };
    },

    methods: {
      onInput(value) {
        this.$emit('input', value);
      },

      generateMask(value) {
        const code = [ /[0-9]/, /[0-9]/ ];
        const firstGroup = [ /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/ ];
        const secondGroup = [ /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/ ];

        return value.length <= 2
          ? [ '(', ...code ]
          : value.length <= 6
          ? [ '(', ...code, ')', ' ', ...firstGroup ]
          : value.length <= 10
          ? [ '(', ...code, ')', ' ', ...firstGroup, '-', ...secondGroup ]
          : [ '(', ...code, ')', ' ', ...firstGroup, /[0-9]/, '-', ...secondGroup ];
      },

      dynamicMask() {
        const numbers = this.thisValue.replace(/[^0-9]/g, '');
        return [ ...this.generateMask(numbers) ];
      },
    },
    
    watch: {
      value: {
        handler(newValue) {
          // Disabling mask so we can input updated value
          this.mask = '';
          this.thisValue = newValue?.replace(/[^0-9]/g, '') || '';
          // Enabling mask back
          this.$nextTick(() => this.mask = this.dynamicMask);
        },
        immediate: true,
      }
    }
  };
</script>
