import { auth } from '@/lib/firebase';
import api from '@/lib/api';

export const state = {};

export const mutations = {}

export const getters = {}

export const actions = {
  async addOneDay() {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post(
        '/debug/add-one-day',
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async subtractOneDay() {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post(
        '/debug/subtract-one-day',
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async transfer() {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/debug/transfer',
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async checkOverdueSubscriptions() {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post(
        '/debug/check-overdue',
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async checkRenewSubscriptions() {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post(
        '/debug/check-renew',
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async checkRenewSubscriptionsSaas() {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/debug/check-renew-saas',
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async sendReminders() {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/debug/send-reminders',
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async sendSurveys() {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/debug/send-surveys',
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async test() {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post(
        '/debug/test',
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
