import { firestoreDb } from '@/lib/firebase';
import DateHelper from './dateHelper';

export const doctorBaseQuery = (userId, start, end) => {
  return firestoreDb.collection('events')
    .where('userId', '==', userId)
    .where('scheduleType', '==', 'once')
    .where('start', '>=', start.toDate())
    .where('start', '<=', end.toDate());
};

export const doctorRepeatingBaseQuery = (userId, start) => {
  return firestoreDb
    .collection('events')
    .where('userId', '==', userId)
    .where('scheduleType', '==', 'repeating')
    .where('details.expiresAt', '>=', start.toDate());
};

export const doctorOnceEvents = async (userId, start, end) => {
  let query = doctorBaseQuery(userId, start, end);

  return query
    .get()
    .then((snapshot) => {
      const events = [];

      snapshot.docs.forEach((d) => {
        const event = { id: d.id, ...d.data() };

        if (event.eventType === 'unavailable') { return; }

        events.push(event);
      });

      return events;
    });
};

export const doctorRepeatingEvents = async (userId, start, end) => {
  let query = doctorRepeatingBaseQuery(userId, start, end);

  return query
    .get()
    .then((snapshot) => {
      const events = [];

      snapshot.docs.forEach((d) => {
        const event = { id: d.id, ...d.data() };

        if (event.eventType === 'unavailable') { return; }

        events.push(...repeatEvent(event, start.startOf('day').toDate(), end.endOf('day').toDate()));
      });

      return events;
    });
};

export const repeatEvent = (event, from, until) => {
  if (!until) { return []; }

  const type = event.details.type;
  let start = DateHelper.date(event.start);
  let end = DateHelper.date(event.end);

  const endsAt = event.details.endsAt || event.details.expiresAt;
  const endsAtJs = endsAt ? DateHelper.date(endsAt) : null;
  const fromJs = from && DateHelper.date(from);
  let untilJs = DateHelper.date(until).endOf('day');

  if (endsAtJs && endsAtJs.isBefore(untilJs)) {
    untilJs = endsAtJs;
  }

  // If from is given and sooner, let's bring the event closer
  // to call less while loops
  if (fromJs && start.isBefore(fromJs)) {
    if (type === 'period' || type === 'unavailable') {
      const diff = fromJs.diff(start, 'day');
      const diffWeeks = Math.ceil(diff / 7);
      start = start.add(diffWeeks, 'week');
      end = end.add(diffWeeks, 'week');
    } else {
      start = start
        .date(fromJs.date())
        .month(fromJs.month())
        .year(fromJs.year());
      end = end
        .date(fromJs.date())
        .month(fromJs.month())
        .year(fromJs.year());

      if (end.isBefore(start)) {
        end = end.add(1, 'day');
      }
    }
  }

  const advance = (days) => {
    start = start.add(days, 'day');
    end = end.add(days, 'day');
  };

  const shouldSkip = (start) => {
    const excludeDates = event.excludeDates || [];
    return fromJs && start.isBefore(fromJs.startOf('day'))
        || excludeDates.some((d) => DateHelper.date(d).isSame(start, 'day'));
  };

  const events = [];

  if (type === 'period' || type === 'unavailable') {
    while (start.isBefore(untilJs)) {
      if (shouldSkip(start)) {
        advance(7);
        continue;
      }

      events.push({
        ...event,
        id: `${ event.id }-${ start.format('YYYY-MM-DD') }`,
        referenceId: event.id,
        start: start.clone(),
        end: end.clone(),
      });

      advance(7);
    }
  } else {
    while (start.isBefore(untilJs)) {
      if (shouldSkip(start)) {
        advance(1);
        continue;
      }
      if (event.days && !event.days.includes(start.day())) {
        advance(1);
        continue;
      }
      
      events.push({
        ...event,
        id: `${ event.id }-${ start.format('YYYY-MM-DD') }`,
        referenceId: event.id,
        start: start.clone(),
        end: end.clone(),
      });

      advance(1);
    }
  }

  return events;
};
