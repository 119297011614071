import store from '@/store'

export default function notAuthOrNotVerifiedMiddleware(to, from, next) {
  const isOk = !store.getters['user/IS_CONNECTED_AND_VERIFIED']
  if (isOk) {
    next()
  } else {
    next({ name: 'home' })
  }
}
