import Vue from 'vue'
import dayjs from 'dayjs';
// import { hidden } from '@/lib/visibility.js'
// if document[hidden] ...

let nextNotificationMoment = null
const burstProtectionDelaySecs = 2

function _innerNotify(title, options) {
  const notification = new Notification(title, options)

  // NOTE: this removes any tray of the notification even existing
  // most browsers will dismiss the notification automatically after about 4 seconds
  // unless the user has explicitly changed the way the notification should behave
  if (options && options.closeOnVisible) {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        // The tab has become visible so clear the now-stale Notification.
        notification.close()
      }
    })
  }
}

function notify(title, body = '', tag = null, duration = 2000, closeOnVisible = false, playSound = false, renotify = true, silent = false, requireInteraction = false) {
  // make them all appear by default (instead of replacing one another)
  tag = tag || Date.now().toString()

  // Toast
  Vue.toasted.info(title + ( body ? ' <br> ' + body : ''), { duration })
  // Snackbar.open({ message: title + ( body ? ' <br> ' + body : ''), type: 'is-info', duration, queue: true })

  // Sounds
  if (playSound) {
    try {
      const alert = new Audio('/audio/hero.mp3')
      alert.play()
    } catch(err) {
      // example: "play failed because user didn't interact with the document"
      // ignore fails here
      console.log(err)
    }
  }

  // Notification API
  if (!('Notification' in window)) {
    console.warn('[WARN] Notification API not found')
    return
  }
  if (Notification.permission !== 'granted') {
    console.warn('[WARN] Notification permission not granted.')
    return
  }

  const options = { body, tag, icon: '/icon.png', lang: 'PT', renotify, silent, requireInteraction, closeOnVisible }

  const now = dayjs()
  if (nextNotificationMoment !== null && nextNotificationMoment.isAfter(now)) {
    const millisDiff = nextNotificationMoment.diff(now)
    setTimeout(() => _innerNotify(title, options), millisDiff)
    nextNotificationMoment = nextNotificationMoment.add(burstProtectionDelaySecs, 'seconds')
    return { delayed: millisDiff }
  } else {
    _innerNotify(title, options)
    nextNotificationMoment = dayjs().add(burstProtectionDelaySecs, 'seconds')
    return { delayed: null }
  }
}

export { notify }
