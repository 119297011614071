<template>
  <v-text-field
    ref="inputRef"
    :placeholder="placeholder"
    v-bind="$attrs"
  />
</template>

<script>
import { watch } from '@vue/composition-api';
import { useCurrencyInput } from 'vue-currency-input';
import { rules } from '@/lib/rules';
import { prettyPrice } from '@/utils';

export default {
  name: 'CurrencyInput',

  props: {
    value: [ String, Number ],

    placeholder: {
      type: String,
      default: prettyPrice(0),
    },
    
    options: {
      type: Object,

      default: () => ({
        currency: 'BRL',
        locale: 'pt-BR',
        autoDecimalDigits: true,
      }),
    },
  },

  data() {
    return {
      rules,
    };
  },

  setup(props) {
    const { inputRef, setValue } = useCurrencyInput(props.options);

    // Watching external value changes
    watch(() => props.value, (value) => setValue(value));

    return { inputRef };
  },
}
</script>
