import { auth, firestoreDb } from '@/lib/firebase';
import api from '@/lib/api';

const RESET = 'RESET';

const getDefaultState = () => ({
  banks: [],
  isAddingBank: false,
  isLoadingBanks: true,
  recipients: [],
  isLoadingRecipients: true,
});

export const state = getDefaultState();

export const mutations = {
  [RESET](state) {
    Object.assign(state, getDefaultState());
  },
}

export const getters = {
  GET_BANKS(state) {
    return state.banks
  },
  GET_IS_ADDING_BANK(state) {
    return state.isAddingBank
  },
  GET_IS_LOADING_BANKS(state) {
    return state.isLoadingBanks
  },
  GET_RECIPIENTS(state) {
    return state.recipients
  },
  GET_IS_LOADING_RECIPIENTS(state) {
    return state.isLoadingRecipients
  },
}

export const actions = {
  async rateUser(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/rating/user',
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchUserRating({ rootGetters }, ratedUserId) {
    const userUid = rootGetters['user/GET_USER_UID'];
    const doc = await firestoreDb
      .collection('ratings')
      .doc('users')
      .collection(ratedUserId)
      .doc(userUid)
      .get();
    return doc.exists ? doc.data() : null;
  },

  async fetchOwnRating({ rootGetters }) {
    const userUid = rootGetters['user/GET_USER_UID'];
    const doc = await firestoreDb
      .collection('users')
      .doc(userUid)
      .collection('metadata')
      .doc('rating')
      .get();
    return doc.exists ? doc.data() : null;
  },

  async rateSpace(_, { ratedSpaceId, eventId, eventStart, answers, comment, }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/rating/space',
        { ratedSpaceId, eventId, eventStart, answers, comment },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchSpaceRating(_, { spaceId, eventId, eventStart }) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/rating/space',
        {
          params: { spaceId, eventId, eventStart },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  reset({ commit }) {
    commit(RESET);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
